import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { listAuditContentTWS } from '../../../graphql/queries';
import { createAuditForm, updateAuditForm } from '../../../graphql/mutations';
import Signature from '../../../components/Signature';
import SoftButton from '../../../components/SoftButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function AuditForm() {
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [content, setContent] = useState([]);
	const disable = location.state.action === 'view' ? true : false;
	const check = location.state.route;
	const oldData = check || [];
	const currentLang = i18n.language;
	const userName = useSelector((state) => state.user.userName);
	const storeId = useSelector((state) => state.user.userStore);
	const hqStaff = true; //userLogged.nickname.includes('HQ');
	const [resultVal, setResultVal] = useState(check ? oldData.Content.map((c) => c.Result) : []);
	const hqView = { display: hqStaff ? undefined : 'none' };
	const category = [
		// ...new Set(
		// 	content.map((c) => {
		// 		return c.Category;
		// 	})
		// )
		'黃金守則',
		'品質',
		'服務',
		'清潔與衛生'
	];
	const [submitLoading, setSubmitLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [imageURL, setImageURL] = useState(check ? oldData.signature : null);
	const [imageURL2, setImageURL2] = useState(check ? oldData.staffSign : null);
	const [formValue, setFormValue] = useState([
		{
			Date: check ? oldData.Date : moment().format('MM/DD/YYYY'),
			Store: check ? oldData.Store : storeId[0],
			Model: check ? oldData.Model : hqStaff ? '' : '業主自評',
			Type: check ? oldData.Type : hqStaff ? '' : '業主自評',
			Content: check ? oldData.Content : [],
			Final: check ? oldData.Final : 0,
			Suggestion: check ? oldData.Suggestion : '',
			Improvement: check ? oldData.Improvement : '',
			id: check ? oldData.id : '',
			Status: '',
			signature: check ? oldData.signature : '',
			staffSign: check ? oldData.staffSign : ''
		}
	]);

	const back = () => navigate('/online_form/audit');
	const cellStyle = {
		fontWeight: 'bold',
		backgroundColor: '#B1B4BA',
		fontSize: 14
	};
	const fontSizeStyle = {
		fontSize: 14
	};
	const fontWeightStyle = {
		fontWeight: 'bold'
		// fontSize: 14
	};
	const headerStyle = {
		fontWeight: 'bold',
		fontSize: 16
	};
	// const handleToggle = (event, toggle) => setToggle(toggle);

	async function getAuditContent() {
		let result;
		try {
			result = await API.graphql({
				query: listAuditContentTWS
			});
			result = result.data.listAuditContentTWS.items;
			result.sort(function (a, b) {
				if (a.Category === '黃金守則' && b.Category !== '黃金守則') {
					return -1; // '黃金守則' comes before other categories
				} else if (a.Category !== '黃金守則' && b.Category === '黃金守則') {
					return 1; // Other categories come after '黃金守則'
				}
				return parseInt(a.Number) - parseInt(b.Number);
			});
			let updateRes = JSON.parse(JSON.stringify(result));
			let contentVal = [];
			if (check === undefined) {
				updateRes.map((u) => {
					u.Result = '';
					u.Weight = 0;
					contentVal.push({ Id: u.id, Details: u.Details, Result: u.Result });
					return '';
				}); //set Result to null;
			} else {
				updateRes.map((u, index) => {
					u.Result = oldData.Content[index].Result;
					u.Details = oldData.Content[index].Details;
					u['Weight'] =
						u.Category === '黃金守則' ? (u.Result === 'true' ? 0 : u.Partition) : u.Result === 'true' ? u.Partition : 0;
					contentVal.push({ Id: u.id, Details: u.Details, Result: u.Result });
					return '';
				});
			}
			formValue[0].Content = contentVal;
			setContent(updateRes);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	}

	useEffect(() => {
		getAuditContent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getInput(e, id, key) {
		let val = e.target.value;
		let contentVal = [];
		switch (key) {
			case 'Store':
			case 'Type':
			case 'Model':
				let form = [...formValue];
				form[0][key] = val;
				setFormValue(form); // in order to get the val instantly to check if required shows
				// if (key === 'Store' && val) getSignature(val);
				break;
			case 'Suggestion':
			case 'Improvement':
				formValue[0][key] = val;
				break;
			case 'Details':
				content.map((c) => {
					if (c.id === id) c[key] = val;
					contentVal.push({ Id: c.id, Details: c.Details, Result: c.Result });
					return '';
				});
				formValue[0].Content = contentVal;
				break;
			case 'Result':
				let sum = 0;
				let curWeight = 0;
				let calculate = formValue[0].Final;
				let inputVal = [...content];
				inputVal.map((c) => {
					if (c.id === id) {
						c[key] = val;
					}
					if (c.Category === '黃金守則') {
						c.Weight = c.Result === 'false' ? c.Partition : 0;
					} else {
						c.Weight = c.Result === 'true' ? c.Partition : 0;
						if (c.Result === 'true') {
							sum += c.Partition * 0.1;
						}
					}
					if (c.Category === '黃金守則') {
						curWeight += c.Weight; //count total weight
					}
					contentVal.push({ Id: c.id, Details: c.Details, Result: c.Result });
					return '';
				});
				calculate = parseInt(sum - sum * curWeight * 0.01);
				formValue[0].Final = calculate;
				formValue[0].Content = contentVal;
				setContent(inputVal);
				// setWeight(curWeight);
				setResultVal(inputVal.map((c) => c.Result));
				break;
			default:
				break;
		}
	}

	const handleSubmit = async (status) => {
		status === 'submitted' ? setSubmitLoading(true) : setSaveLoading(true);
		let newContent = formValue[0].Content.map((c) => JSON.stringify(c));
		let create = {
			Date: formValue[0].Date,
			Content: newContent,
			Final: formValue[0].Final,
			Store: formValue[0].Store,
			Improvement: formValue[0].Improvement,
			Suggestion: formValue[0].Suggestion,
			Type: formValue[0].Type,
			Model: formValue[0].Model,
			Status: [status + '@' + moment().toISOString()],
			signature: hqStaff ? imageURL : '',
			staffSign: hqStaff ? imageURL2 : '',
			Author: userName
		};
		if (check) {
			create = Object.assign({ id: check.id }, create);
			create._version = check._version;
			await API.graphql({
				query: updateAuditForm,
				variables: { input: create }
			});
		} else {
			await API.graphql({
				query: createAuditForm,
				variables: { input: create }
			});
		}
		setTimeout(() => {
			back();
			status === 'submitted' ? setSubmitLoading(false) : setSaveLoading(false);
		}, 3000);
	};

	return (
		<Box>
			<Typography align="left" paddingBottom={2}>
				<SoftButton variant="contained" size="small" startIcon={<ArrowBackIosIcon />} onClick={back} color={'light'}>
					{t('auditForm.Back')}
				</SoftButton>
			</Typography>
			<TableContainer component={Paper}>
				<Table
					size="small"
					aria-label="a dense table"
					sx={{
						'& .MuiTableHead-root': {
							display: 'table-header-group'
						},
						minWidth: { md: 650, xs: 730 }
					}}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={6} align="left">
								<Grid container>
									<Grid item md={5} xs={6}>
										<Chip
											variant="outlined"
											icon={disable ? <RemoveRedEyeIcon /> : <EditIcon />}
											label={disable ? t('auditForm.view') : t('auditForm.edit')}
											sx={{
												color: disable ? '#f14a32' : '#039c19',
												'& .MuiChip-icon': { color: disable ? '#f14a32' : '#039c19' }
											}}
										/>
									</Grid>
									<Grid item md={7} xs={6} sx={{ fontWeight: 'bold', fontSize: 16 }}>
										{t('auditForm.Title')}
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow align={'center'} style={{ fontWeight: 'bold' }}>
							<TableCell colSpan={3} align={'left'} style={{ ...fontWeightStyle }}>
								{t('auditForm.Date')}: {formValue[0].Date}
							</TableCell>
							<TableCell align={'center'} style={{ ...fontWeightStyle }} sx={{ display: check ? 'none' : undefined }}>
								{t('auditForm.Time')}: {moment().format('LT')}
							</TableCell>
							<TableCell align={'center'} style={{ ...fontWeightStyle }} sx={{ display: check ? 'none' : undefined }}>
								{t('auditForm.Author')}: {userName}
							</TableCell>
							<TableCell colSpan={3} align={'right'} sx={{ paddingTop: 2 }}>
								<Grid container>
									<Grid item>
										<Autocomplete
											disabled={disable}
											id="combo-box-demo"
											options={storeId}
											value={formValue[0].Store}
											onBlur={(e) => getInput(e, null, 'Store')}
											sx={{ width: 200 }}
											renderInput={(params) => (
												<TextField
													{...params}
													label={t('auditForm.Please select a store')}
													size="small"
													InputLabelProps={{ style: { fontSize: '14px' } }}
												/>
											)}
										/>
									</Grid>
									<Grid item>
										{formValue[0].Store ? null : (
											<Typography color="error" align="center" padding={1} style={{ fontSize: 12 }}>
												{t('auditForm.*Required')}
											</Typography>
										)}
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={6} align={'left'} style={{ ...fontWeightStyle }}>
								{t('auditForm.Model')}:
								<FormControl sx={{ marginLeft: 3 }} disabled={disable}>
									<RadioGroup
										row
										aria-labelledby="demo-row-radio-buttons-group-label"
										name="row-radio-buttons-group"
										value={formValue[0].Model}
										onChange={(e) => getInput(e, null, 'Model')}
										sx={{
											'& .MuiFormControlLabel-label': {
												fontWeight: 400
											}
										}}>
										<FormControlLabel
											value="Notification"
											control={<Radio size="small" />}
											label={t('auditForm.Notification')}
										/>
										<FormControlLabel
											value="1st"
											control={<Radio size="small" />}
											label={t('auditForm.Without notification 1')}
										/>
										<FormControlLabel
											value="2nd"
											control={<Radio size="small" />}
											label={t('auditForm.Without notification 2')}
										/>
										<FormControlLabel
											value="3rd"
											control={<Radio size="small" />}
											label={t('auditForm.Without notification 3')}
										/>
										<FormControlLabel
											value="4th"
											control={<Radio size="small" />}
											label={t('auditForm.Without notification 4')}
										/>
										{formValue[0].Model ? null : (
											<Typography color="error" style={{ fontSize: 12 }}>
												{t('auditForm.*Required')}
											</Typography>
										)}
									</RadioGroup>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={6} align={'left'} style={{ ...fontWeightStyle }}>
								{t('auditForm.Type')}:
								<FormControl sx={{ marginLeft: 3 }} disabled={disable}>
									<RadioGroup
										row
										aria-labelledby="demo-controlled-radio-buttons-group"
										name="controlled-radio-buttons-group"
										value={formValue[0].Type}
										key={'type'}
										onChange={(e) => getInput(e, null, 'Type')}
										sx={{
											'& .MuiFormControlLabel-label': {
												fontWeight: 400
											}
										}}>
										<FormControlLabel
											value="Regular inspection"
											control={<Radio size="small" />}
											label={t('auditForm.Regular inspection')}
										/>
										<FormControlLabel
											value="Contract renewal inspection"
											control={<Radio size="small" />}
											label={t('auditForm.Contract inspection')}
										/>
										{formValue[0].Type ? null : (
											<Typography color="error" style={{ fontSize: 12 }}>
												{t('auditForm.*Required')}
											</Typography>
										)}
									</RadioGroup>
								</FormControl>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell align={'center'} style={{ width: '10%', ...cellStyle }}>
								{t('auditForm.Partition')}
							</TableCell>
							<TableCell align={'center'} style={{ width: '10%', ...cellStyle }}>
								{t('auditForm.No.')}
							</TableCell>
							<TableCell colSpan={2} align={'center'} style={{ width: '40%', ...cellStyle }}>
								{t('auditForm.Content')}
							</TableCell>
							<TableCell align={'center'} style={{ width: '20%', ...cellStyle }}>
								{t('auditForm.Result')}
							</TableCell>
							<TableCell align={'center'} style={{ width: '20%', ...cellStyle }}>
								{t('auditForm.Details')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow key={`category_${category[0]}`}>
							<TableCell colSpan={6} align="center" style={{ ...fontWeightStyle, backgroundColor: '#fff2cc' }}>
								{t(`auditForm.${category[0]}`)}
							</TableCell>
						</TableRow>
						{content.map((c, index) => (
							<React.Fragment key={c.Partition + c.Number + index}>
								{index > 0 && content[index - 1].Category !== c.Category && (
									<TableRow key={`category_${c.Category}`}>
										<TableCell colSpan={6} align="center" style={{ ...fontWeightStyle, backgroundColor: '#CADAEA' }}>
											{t(`auditForm.${c.Category}`)}
										</TableCell>
									</TableRow>
								)}
								<TableRow>
									<TableCell align="center" style={{ ...fontSizeStyle }}>
										{c.Category === '黃金守則' ? c.Partition + '%' : c.Partition * 0.1}
									</TableCell>
									<TableCell align="center" style={{ ...fontSizeStyle }}>
										{c.Number}
									</TableCell>
									<TableCell align="left" style={{ ...fontSizeStyle }} colSpan={2}>
										{currentLang.includes('en') ? c.Content : c.ZH}
									</TableCell>
									<TableCell align="center">
										<Grid container>
											<Grid item>
												<FormControl size="small" disabled={disable}>
													<RadioGroup
														row
														aria-labelledby="demo-controlled-radio-buttons-group"
														name="controlled-radio-buttons-group"
														value={c.Result}
														key={c.id}
														onChange={(e) => getInput(e, c.id, 'Result')}
														sx={{
															'& .MuiFormControlLabel-label': {
																fontWeight: 400
															}
														}}>
														<FormControlLabel
															value={true}
															control={<Radio size="small" />}
															label={t('auditForm.Pass')}
														/>
														<FormControlLabel
															value={false}
															control={<Radio size="small" />}
															label={t('auditForm.Fail')}
														/>
													</RadioGroup>
												</FormControl>
											</Grid>
											<Grid item>
												{c.Result ? null : (
													<Typography color="error" style={{ fontSize: 12 }}>
														{t('auditForm.*Required')}
													</Typography>
												)}
											</Grid>
										</Grid>
									</TableCell>
									<TableCell align="center">
										<TextField
											name="Details"
											multiline
											disabled={disable}
											id={c.id}
											size="medium"
											defaultValue={c.Details}
											onChange={(e) => getInput(e, c.id, 'Details')}></TextField>
										{/* {formValue[0].Final} */}
										{/* score:{score},weight:{weight}, individual: {c.Weight} final:{formValue[0].Final} */}
									</TableCell>
								</TableRow>
							</React.Fragment>
						))}
						<TableRow>
							<TableCell colSpan={6} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								{t('auditForm.Final')}: {formValue[0].Final}
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={4} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								{t('auditForm.Suggestion')}
							</TableCell>
							<TableCell colSpan={3} align={'center'} style={{ ...cellStyle, ...fontSizeStyle }}>
								{t('auditForm.Improvement')}
							</TableCell>
						</TableRow>
						<TableRow sx={hqView}>
							<TableCell colSpan={4} align={'center'} style={{ ...fontWeightStyle, ...fontSizeStyle }}>
								<TextField
									multiline
									fullWidth
									rows={6}
									disabled={disable}
									defaultValue={formValue[0].Suggestion}
									onChange={(e) => getInput(e, null, 'Suggestion')}></TextField>
							</TableCell>
							<TableCell colSpan={3} align={'center'} style={{ ...fontWeightStyle, ...fontSizeStyle }}>
								<TextField
									multiline
									fullWidth
									rows={6}
									disabled={disable}
									defaultValue={formValue[0].Improvement}
									onChange={(e) => getInput(e, null, 'Improvement')}></TextField>
							</TableCell>
						</TableRow>
					</TableBody>
					<TableRow sx={hqView}>
						<TableCell colSpan={4} align={'center'}>
							<Typography style={{ ...headerStyle }}>{t('prep.Owner Signature')}</Typography>
							<Signature disable={disable} signURL={imageURL} handleSignatureDone={(arg) => setImageURL(arg)} />
						</TableCell>
						<TableCell colSpan={4} align={'center'}>
							<Typography style={{ ...headerStyle }}>{t('prep.Supervisor Signature')}</Typography>
							<Signature disable={disable} signURL={imageURL2} handleSignatureDone={(arg) => setImageURL2(arg)} />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell colSpan={6} align={'center'}>
							<SoftButton
								value="放棄"
								color="primary"
								onClick={back}
								size="small"
								startIcon={<DeleteForeverIcon color="error" />}
								variant="outlined"
								disabled={disable}>
								<Typography fontSize={14}>{t('auditForm.Discard')}</Typography>
							</SoftButton>
							&nbsp;
							<SoftButton
								value="儲存"
								color="secondary"
								size="small"
								onClick={() => handleSubmit('working')}
								variant="outlined"
								startIcon={<SaveIcon color="secondary" />}
								disabled={!formValue[0].Store || disable}>
								<Typography fontSize={14} sx={{ display: !saveLoading ? undefined : 'none' }}>
									{t('auditForm.Save')}
								</Typography>
								<CircularProgress size={24} sx={{ display: saveLoading ? undefined : 'none' }} />
							</SoftButton>
							&nbsp;
							<SoftButton
								value="提交"
								color="success"
								size="small"
								onClick={() => handleSubmit('submitted')}
								variant="outlined"
								startIcon={<CloudUploadIcon color="success" />}
								disabled={
									!(
										formValue[0].Model &&
										formValue[0].Type &&
										formValue[0].Store &&
										!resultVal.includes('') &&
										resultVal.length > 0
									) || disable
								}>
								<Typography fontSize={14} sx={{ display: !submitLoading ? undefined : 'none' }}>
									{t('auditForm.Submit')}
								</Typography>
								<CircularProgress size={24} sx={{ display: submitLoading ? undefined : 'none' }} />
							</SoftButton>
						</TableCell>
					</TableRow>
				</Table>
			</TableContainer>
		</Box>
	);
}
