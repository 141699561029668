import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { listOverseasShippings, getOverseasOrder } from '../../graphql/queries';
import { createOverseasOrder, deleteOverseasOrder, updateOverseasOrder } from '../../graphql/mutations';
// import { useAuthenticator } from '@aws-amplify/ui-react';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';
import SoftButton from '../../components/SoftButton';
import SoftBadge from '../../components/SoftBadge';
import { ExportExcel } from '../../components/ExportExcel';
import { sendMail } from '../../scripts/lambda';
import { getCompany, listAllOrders } from '../../scripts';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';

function Dashboard() {
	const area = useSelector((state) => state.user.userArea);
	const role = useSelector((state) => state.user.userRole);
	const userType = useSelector((state) => state.user.userType);
	const [listOrders, setListOrders] = useState([]);
	const [orders, updateOrders] = useState([]);
	const [fOrders, updateFOrders] = useState([]);
	const [dataList, setDataList] = useState({});
	const [inv, updateItem] = useState([]);
	const [storeVal, updateStore] = useState({});
	const [expanded, setExpanded] = useState(false);
	const [orderCountry, setOrderCountry] = useState(['US']);
	const [curType, setCurType] = useState('US');
	const [orderSet, setOrderSet] = useState({});
	const [orderRoute, setOrderRoute] = useState('/usorder');
	const [open, setOpen] = useState(false);
	const [mergeOpen, setMergeOpen] = useState(false);
	const [mergeOptions, setMergeOptions] = useState([]);
	const [areaOption, setAreaOption] = useState([]);
	const [mergeOrders, setMergeOrders] = useState([]);
	const [mergeID, setMergeID] = useState([]);
	const [delVal, setDelVal] = useState({});
	const [searchItem, setSearchItem] = useState('');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [pageIndex, setPageIndex] = useState(0);
	const [countStatus, setCountStatus] = useState([]);
	const [overallOpen, setOverallOpen] = useState(false);
	const [mergeDone, setMergeDone] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const sColor = { pending: 'pending', processing: 'processing', completed: 'completed' };
	const originalPath = window.location.origin + window.location.pathname;
	const color = {
		pending: '#f75d2d',
		processing: '#0f62e6',
		completed: '#019923de',
		Total: 'black'
	};

	const handleChange = (panel, id) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		let curPath = isExpanded ? `${originalPath}?a=${curType}&i=${id}` : originalPath;
		window.history.replaceState(null, null, curPath);
	};

	const handleOverall = () => setOverallOpen(!overallOpen);

	const handleCountry = (event, country) => {
		if (country !== null) {
			setCurType(country);
			setOrderRoute('/' + country.toLowerCase() + 'order');
			getOrders(country);
		}
		window.history.replaceState(null, null, originalPath);
		setExpanded(false);
	};

	const handleDelVal = (item, show) => {
		setDelVal(item);
		setOpen(show);
	};

	const handleClickOpen = (item) => handleDelVal(item, true);

	const handleClose = () => handleDelVal({}, false);

	const handleMergeOpen = () => setMergeOpen(true);

	const handleMergeClose = () => {
		setMergeOpen(false);
		setMergeOptions([]);
		setMergeOrders([]);
	};

	const descriptionElementRef = useRef(null);

	const handleChangePage = (event, newPage) => {
		if (newPage > page) {
			setPageIndex((pre) => pre + rowsPerPage);
		} else {
			setPageIndex((pre) => pre - rowsPerPage);
		}
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPageIndex(0);
		setPage(0);
	};

	useEffect(() => {
		queryOrders();
		getCompany().then((i) => updateStore(i));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const href = window.location.href;
		let curArea = 'US';
		if (userType !== 'Z_Supervisor') {
			if (
				(role === 'Owner' && area[0] === 'CA_Van') ||
				(role === 'TW_staff' && userType !== 'Z_Manager' && userType !== 'Z_Observer')
			) {
				curArea = 'TW';
				setView(['TW'], '/tworder', 'TW'); //only view TW
			} else if (
				(role === 'Owner' && area[0].startsWith('US')) ||
				(role === 'TW_staff' && (userType === 'Z_Manager' || userType === 'Z_Observer'))
			) {
				//with access to two countries
				if (href.includes('?') && href.split('&')[0].split('=')[1] === 'US') {
					setView(['TW', 'US'], '/usorder', 'US'); // open US order via email
				} else {
					curArea = 'TW';
					setView(['TW', 'US'], '/tworder', 'TW'); // open TW order via email
				}
			} // the rest use default value for only view US
			getOrders(curArea);
			if (mergeOpen) {
				const { current: descriptionElement } = descriptionElementRef;
				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
			getItem();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [area, listOrders]);

	const setView = (country, route, current) => {
		setOrderCountry(country);
		setOrderRoute(route);
		setCurType(current);
	};

	function getMerge(e, item) {
		let ID = item.id;
		let Morders = [...mergeOrders];
		let MergeID = mergeOrders.map((m) => m.id.split('-')[0]);
		if (e.target.checked === true) {
			Morders.push(item);
			MergeID.push(item.id.split('-')[0]);
		} else {
			Morders = Morders.filter((item) => item.id !== ID);
			MergeID = MergeID.filter((item) => item !== ID.split('-')[0]);
		}
		setMergeID(MergeID);
		setMergeOrders(Morders);
	}

	async function MergeOrder() {
		setLoading(true);
		let name = mergeOrders[0].orderby + '@' + mergeOrders[0].orderarea;
		let shipFrom = mergeOrders[0].orderType;
		let qty = [];
		let amount = [];
		let code = [];
		let sumQty = 0;
		let sumAmount = 0;
		let val = [];
		mergeOrders.map((m) =>
			m.orders.map((o) => {
				if (code.includes(o.split('@')[0])) {
					qty[code.indexOf(o.split('@')[0])] += parseFloat(o.split('@')[1]);
					amount[code.indexOf(o.split('@')[0])] += parseFloat(o.split('@')[2]);
				} else {
					code.push(o.split('@')[0]);
					qty.push(parseFloat(o.split('@')[1]));
					amount.push(parseFloat(o.split('@')[2]));
				}
				sumQty += parseFloat(o.split('@')[1]);
				sumAmount += parseFloat(o.split('@')[2]);
				return '';
			})
		);

		code.map((c, index) => val.push(c + '@' + qty[index] + '@' + amount[index]));
		try {
			for (const order of mergeOrders) {
				const variables = {
					id: order.id,
					_version: order.version
				};
				await API.graphql({
					query: deleteOverseasOrder,
					variables: { input: variables }
				});
			}
			const variables = {
				Code: val,
				Name: name,
				Qty: sumQty,
				Amount: sumAmount,
				Status: ['pending@' + new Date().toISOString()],
				OrderType: shipFrom
			};

			await API.graphql({
				query: createOverseasOrder,
				variables: { input: variables }
			});
			setMergeDone(true);
		} catch (error) {
			console.log('error', error);
		}

		setTimeout(() => {
			handleMergeClose();
			queryOrders();
			setMergeDone(false);
			setLoading(false);
		}, 3000);
	}

	async function queryOrders() {
		const res = await listAllOrders();
		setListOrders(res);
	}

	async function getOrders(curArea) {
		let set = {};
		try {
			const posts = listOrders.filter((r) => r.OrderType === curArea);
			const allOrders = listOrders.filter((f) => area.includes(f.Name.split('@')[1])); //show orders from vibible areas
			let areas = allOrders.map((i) => i.OrderType);
			for (let i of areas) {
				set[i] = set[i] ? set[i] + 1 : 1;
			}
			setOrderSet(set);
			let allStatus = allOrders.map((p) => p.Status[p.Status.length - 1].split('@')[0]);
			let status = {};
			let list = ['pending', 'processing', 'completed'];
			list.map((s) => (status[s] = 0));
			status['Total'] = allOrders.length;
			for (let i of allStatus) {
				status[i] = status[i] ? status[i] + 1 : 1;
			}
			setDataList(status);
			let val = [];
			let remaining = '';
			let curMonth = new Date().getMonth() + 1;
			let today = new Date().getDate();
			posts.sort(function (a, b) {
				if (a.updatedAt > b.updatedAt) {
					return -1;
				}
				if (a.updatedAt < b.updatedAt) {
					return 1;
				}
				return 0;
			});

			for (let i in posts) {
				if (posts[i] !== undefined) {
					let orderval = posts[i].Name.split('@');
					let sVal = [...posts[i].Status];
					sVal.sort(function (a, b) {
						if (a.split('@')[1] < b.split('@')[1]) {
							return -1;
						}
						if (a.split('@')[1] > b.split('@')[1]) {
							return 1;
						}
						return 0;
					});
					let oDate = new Date(posts[i].updatedAt).getDate();
					let oMonth = new Date(posts[i].updatedAt).getMonth() + 1;
					if (oMonth === curMonth) {
						remaining = oDate < 15 ? 15 - today : 30 - today;
						if (oDate === 30 && today === 31 && remaining < 1) {
							remaining = 15;
						} else if (oDate === 30 && remaining < 1) {
							remaining = 16;
						} else if (oDate === 31 && remaining < 1) {
							remaining = 15;
						} else if (oDate === 31 || oDate === 30) {
							remaining = 15 - today;
						}
					}
					if (area.includes(orderval[1])) {
						val.push({
							id: posts[i].id,
							orderby: orderval[0],
							orderarea: orderval[1],
							orders: posts[i].Code,
							created_at: posts[i].updatedAt,
							quantity: posts[i].Qty,
							status: sVal,
							used_status: sVal[sVal.length - 1].split('@')[0],
							amount: posts[i].Amount,
							remaining: remaining,
							orderType: posts[i].OrderType,
							version: posts[i]._version
						});
					}
				}
			}
			let count = [];
			val.forEach((item) => {
				const area = item.orderarea;
				const status = item.used_status;
				if (!count[area]) {
					count[area] = { pending: 0, processing: 0, completed: 0 };
				}
				count[area][status]++;
			});
			const result = Object.keys(count).map((area) => ({
				orderarea: area,
				...count[area]
			}));
			setCountStatus(result);
			updateOrders(val);
			updateFOrders(val);
			let areaOption = [];
			val.map((item) => {
				if (item.orderType === curArea) {
					areaOption.push(item.orderarea);
				}
				return '';
			});
			let uniarea = [...new Set(areaOption)];
			setAreaOption(uniarea);
		} catch (error) {
			console.log('Error retrieving order', error);
		}
	}

	function getArea(area) {
		setMergeOrders([]);
		setMergeOptions([]);
		setMergeID([]);
		let mergeOrders = orders.filter(
			(item) =>
				item.orderarea === area && item.status[item.status.length - 1].split('@')[0] === 'pending' && item.remaining > 0
		);
		setMergeOptions(mergeOrders);
	}

	async function deleteOrder() {
		try {
			const variables = {
				id: delVal.id,
				_version: delVal.version
			};
			await API.graphql({
				query: deleteOverseasOrder,
				variables: { input: variables }
			});
		} catch (error) {
			console.log('error', error);
		}
		setOpen(false);
		navigate('/dashbaord');
	}

	async function getItem() {
		let allItem = [];
		try {
			const variables = {
				limit: 500
			};
			let hasNextPage = true;
			while (hasNextPage) {
				const { data: res } = await API.graphql({ query: listOverseasShippings, variables });
				const { items: item, nextToken } = res.listOverseasShippings;
				variables.nextToken = nextToken;
				allItem = allItem.concat(item);
				hasNextPage = nextToken ? true : false;
			}
			const posts = allItem;
			let userInv = [];
			posts.sort(function (a, b) {
				if (a.Code < b.Code) {
					return -1;
				}
				if (a.Code > b.Code) {
					return 1;
				}
				return 0;
			});

			for (let i in posts) {
				if (posts[i] !== undefined) {
					if (userType.startsWith('Z_')) {
						userInv.push(posts[i]);
					} else if (area.length > 0 && posts[i].Area.includes(area[0])) {
						userInv.push(posts[i]);
					} else if (area.length > 0 && posts[i].Area.includes(area[0].split('_')[0])) {
						userInv.push(posts[i]);
					}
				}
			}
			updateItem(userInv);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	}

	async function updateOrder(orderid, status) {
		try {
			const graphql = await API.graphql({
				query: getOverseasOrder,
				variables: { id: orderid }
			});
			const original = graphql.data.getOverseasOrder;
			const variables = {
				id: original.id,
				_version: original._version
			};
			if (original.Status.length === 2 && status === 'processing') {
				variables['Status'] = [...[original.Status[0]], 'processing@' + new Date().toISOString()];
			} else {
				variables['Status'] = [...original.Status, `${status}@${new Date().toISOString()}`];
			}
			await API.graphql({
				query: updateOverseasOrder,
				variables: { input: variables }
			});
		} catch (error) {
			console.log('error', error);
		}
		queryOrders();
		let filtered = [];
		orders.map((item) => {
			if (item.orderType === curType) {
				filtered.push(item);
			}
			return '';
		});
		let item = '';
		filtered
			.filter((f) => f.id === orderid)
			.map((i) => {
				return (item = i);
			});
		if (status === 'processing' || status === 'completed') {
			sendMail(
				item.orderby,
				storeVal[item.orderarea + '/Email'],
				storeVal[item.orderarea],
				item.quantity,
				item.orders.length,
				item.amount,
				item.orderarea,
				'',
				curType,
				'',
				item.id.split('-')[0],
				status === 'processing' ? 'receive' : 'complete'
			);
		}
	}

	function searchOrder(o) {
		let eVal = [];
		let filtered = [];
		orders.map((item) => {
			if (item.orderType === curType) {
				filtered.push(item);
			}
			return '';
		});
		let sOrder = filtered[o].orders.slice().sort();
		for (let i in sOrder) {
			let oitem = sOrder[i].split('@');
			let icode = oitem[0];
			let iqty = oitem[1];
			let iprice = oitem[2];
			for (let p in inv) {
				if (icode === inv[p].Code) {
					let cSize = inv[p].CartonSize ? inv[p].CartonSize.split('*') : 0;
					eVal.push({
						Category: inv[p].Category,
						ProductName: inv[p].Product,
						Code: inv[p].Code,
						UnitPrice: iprice / iqty,
						OrderingQty: parseInt(iqty),
						Amount: Math.round(iprice * 100) / 100,
						ProductZH: inv[p].ZH,
						PackagingSize: inv[p].Size,
						ExpiryDate: parseInt(inv[p].Expiry) > 0 ? inv[p].Expiry + 'months' : 'N/A',
						Unit: inv[p].Unit,
						Nw: inv[p].NW,
						TotalNetWeight: inv[p].NW * parseInt(iqty) || '',
						Gw: inv[p].GW,
						TotalGrossWeight: inv[p].GW * parseInt(iqty) || '',
						CBM: cSize === 0 ? '' : Math.round(((cSize[0] * cSize[1] * cSize[2] * iqty) / 1000000) * 100) / 100,
						CartonSize: inv[p].CartonSize,
						Note: inv[p].Note,
						orderarea: filtered[o].orderarea,
						HS_Code: inv[p].HSCode,
						Brand: inv[p].Brand,
						Material: inv[p].Material,
						orderid: filtered[o].id
						// orderby:fOrders[o].orderby,
						// updated_at:fOrders[o].created_at,
					});
				}
			}
		}
		return eVal;
	}

	function DetailCard() {
		let filtered = fOrders.slice(pageIndex, pageIndex + rowsPerPage);
		return filtered.map((item, index) => (
			<Accordion
				key={item.id}
				expanded={expanded === 'panel' + index || window.location.href.includes(item.id.split('-')[0])}
				onChange={handleChange('panel' + index, item.id.split('-')[0])}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={'panel' + index + 'bh-content' + index}
					id={'panel' + index + 'bh-header'}
					sx={{ mr: 2, ml: 1 }}>
					<Grid container>
						<Grid item xs={12} md={5} ml={2}>
							<SoftTypography sx={{ width: 300, flexShrink: 0 }} variant="h6" fontWeight="medium" fontSize={15}>
								{new Date(item.created_at).toLocaleString()}
								&nbsp;
								{item.used_status === 'pending' && item.remaining > 0 ? (
									<SoftBadge
										variant="contained"
										badgeContent={t(item.remaining + ' ' + t('dashboard.Remaining'))}
										color="silver"
										size="xs"
										container
									/>
								) : null}
							</SoftTypography>
						</Grid>
						<Grid item xs={12} md={5} ml={2}>
							<SoftTypography variant="h6" fontWeight="medium" sx={{ color: 'text.secondary' }} fontSize={13}>
								<Icon variant="fill" style={{ verticalAlign: 'middle' }}>
									description
								</Icon>
								{item.id.split('-')[0]} - {item.orderarea + ' -'} {item.orderby} &nbsp;
								<SoftBadge
									variant="gradient"
									badgeContent={t('dashboard.' + item.used_status)}
									color={sColor[item.used_status]}
									size="xs"
									container
								/>
							</SoftTypography>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container spacing={3} key={item.id + index}>
						{userType !== 'Z_Viewer' && userType !== 'Z_Observer' ? (
							<Grid item xs={12} display="flex" justifyContent="flex-end">
								{item.used_status === 'pending' && item.remaining > 0 ? ( //!=='completed'
									<FormControl variant="outlined">
										<Tooltip title={t('dashboard.Edit')}>
											<SoftButton
												color="dark"
												variant="outlined"
												iconOnly
												onClick={() => {
													navigate(orderRoute, { state: searchOrder(pageIndex + index) });
												}}>
												<Icon>edit</Icon>
											</SoftButton>
										</Tooltip>
									</FormControl>
								) : null}
								&nbsp;
								{userType === 'Z_Manager' && item.used_status === 'pending' ? (
									<FormControl variant="outlined">
										<Tooltip title={t('dashboard.Processing')}>
											<SoftButton
												color="info"
												variant="outlined"
												iconOnly
												onClick={() => {
													updateOrder(item.id, 'processing');
												}}>
												<Icon>done</Icon>
											</SoftButton>
										</Tooltip>
									</FormControl>
								) : null}
								&nbsp;
								{userType === 'Z_Manager' && item.used_status === 'processing' ? (
									<FormControl variant="outlined">
										<Tooltip title={t('dashboard.Pending')}>
											<SoftButton
												color="secondary"
												variant="outlined"
												iconOnly
												onClick={() => {
													updateOrder(item.id, 'pending');
												}}>
												<Icon>undo</Icon>
											</SoftButton>
										</Tooltip>
									</FormControl>
								) : null}
								&nbsp;
								{userType === 'Z_Manager' && item.used_status === 'processing' ? (
									<FormControl variant="outlined">
										<Tooltip title={t('dashboard.Completed')}>
											<SoftButton
												color="error"
												variant="outlined"
												iconOnly
												onClick={() => {
													updateOrder(item.id, 'completed');
												}}>
												<Icon>verified</Icon>
											</SoftButton>
										</Tooltip>
									</FormControl>
								) : null}
								&nbsp;
								{item.used_status === 'pending' && (item.remaining > 0 || userType !== 'Owner') ? (
									<FormControl variant="outlined">
										<Tooltip title={t('dashboard.Delete')}>
											<SoftButton color="error" variant="outlined" iconOnly onClick={() => handleClickOpen(item)}>
												<Icon>delete forever</Icon>
											</SoftButton>
										</Tooltip>
									</FormControl>
								) : null}
								&nbsp;
								<ExportExcel
									excelData={searchOrder(pageIndex + index)}
									created={item.created_at}
									qty={item.quantity}
									final={item.amount}
									company={storeVal[item.orderarea]}
									exportType="Excel"
									contract={storeVal[item.orderarea + '/Contract']}
									orderArea={item.orderarea}
									phone={storeVal[item.orderarea + '/Phone']}
									address={storeVal[item.orderarea + '/Address']}
									currency={storeVal[item.orderarea + '/Currency']}
									owner={userType === 'Owner'}
								/>
							</Grid>
						) : null}
						<Grid item xs>
							<SoftBox lineHeight={1.5}>
								<SoftTypography display="block" variant="h6" fontWeight="regular">
									{t('Item')}
								</SoftTypography>
								<SoftTypography variant="h6" fontWeight="bold">
									{item.orders.length}
								</SoftTypography>
							</SoftBox>
						</Grid>
						<Grid item xs>
							<SoftBox lineHeight={1.5}>
								<SoftTypography variant="h6" fontWeight="regular">
									{t('Quantity')}
								</SoftTypography>
								<SoftTypography variant="h6" fontWeight="bold">
									{item.quantity.toLocaleString()}
								</SoftTypography>
							</SoftBox>
						</Grid>
						<Grid item xs>
							<SoftBox lineHeight={1.5}>
								<SoftTypography variant="h6" fontWeight="regular">
									{t('Total amount')}
								</SoftTypography>
								<SoftTypography variant="h6" fontWeight="bold">
									${parseFloat(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2 })}
								</SoftTypography>
							</SoftBox>
						</Grid>
					</Grid>
					<Divider />
					{searchOrder(pageIndex + index).map((item, index) => {
						return (
							<SoftBox
								component="li"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								key={item.ProductName + index + item.OrderingQty}>
								<Grid
									container
									spacing={3}
									key={item.ProductName + index + item.OrderingQty}
									style={{ paddingTop: 12 }}>
									<Grid item xs>
										<SoftTypography display="block" variant="h6" fontWeight="regular">
											{item.ProductName.charAt(0) + item.ProductName.toLowerCase().slice(1)}&nbsp;
											{item.ProductZH}
										</SoftTypography>
									</Grid>
									<Grid item xs>
										<SoftTypography variant="h6" fontWeight="regular">
											{item.OrderingQty}
										</SoftTypography>
									</Grid>
									<Grid item xs>
										<SoftTypography variant="h6" fontWeight="regular">
											$
											{parseFloat(item.Amount).toLocaleString(undefined, {
												minimumFractionDigits: 2
											})}
										</SoftTypography>
									</Grid>
								</Grid>
							</SoftBox>
						);
					})}
				</AccordionDetails>
			</Accordion>
		));
	}

	function NoCard() {
		return (
			<div>
				<SoftBox p={2} mx={3} display="flex" justifyContent="center">
					<SoftBox display="grid" color="error">
						<Icon fontSize="large">add_circle</Icon>
					</SoftBox>
				</SoftBox>
				<SoftBox pb={6} px={2} pt={6} textAlign="center" lineHeight={1.25}>
					<SoftTypography variant="h6" fontWeight="bold">
						{t('dashboard.No order')}
					</SoftTypography>
					<br />
					<SoftTypography variant="h6" color="text" fontWeight="bold">
						{t('dashboard.No order msg')}
					</SoftTypography>
					<Divider />
					<Link to={orderRoute}>
						<SoftButton color="primary" variant="outlined" size="small">
							{t('dashboard.Create new order')}
						</SoftButton>
					</Link>
				</SoftBox>
			</div>
		);
	}

	const handleSearchChange = (e) => {
		let search = e.target.value;
		setPage(0);
		setPageIndex(0);
		setSearchItem(search);
		updateFOrders((prev) => {
			prev = orders.filter((item) => {
				let ignore = new RegExp(search, 'i'); //ignore case-sensitive
				return (
					ignore.test(item.id.split('-')[0]) ||
					ignore.test(item.orderby) ||
					ignore.test(item.orderarea) ||
					ignore.test(item.used_status)
				);
			});
			return prev;
		});
	};

	const Overall = () => {
		return countStatus.map((status, index) => (
			<SoftBox p={1} key={index}>
				<Card sx={{ width: 300, height: 120, backgroundColor: '#eeeeee', boxShadow: 3, textAlign: 'center', p: 1 }}>
					<CardContent>
						<Typography fontSize={14} fontWeight="bold" style={{ color: 'black' }}>
							{status.orderarea}
						</Typography>
						<Grid container pt={1} justifyContent={'space-between'}>
							{Object.keys(color)
								.slice(0, -1)
								.map((info) => (
									<Grid item md={4} key={info}>
										<Typography color={color[info]} fontSize={18} fontWeight={'bold'}>
											{status[info]}
										</Typography>
										<Typography color={'text.secondary'} fontSize={14}>
											{t(`dashboard.${info}`)}
										</Typography>
									</Grid>
								))}
						</Grid>
					</CardContent>
				</Card>
			</SoftBox>
		));
	};

	return (
		<>
			<SoftBox>
				<Grid container p={2}>
					{Object.keys(dataList).map((i, index) => {
						return (
							<Grid item md={3} xs={6} p={1} key={index}>
								<Card
									sx={{
										width: 160,
										height: 70,
										pt: 1,
										boxShadow: 2,
										textAlign: 'center',
										'&:hover': {
											background: 'rgb(245, 245, 245, 1)'
										}
									}}>
									<Typography fontWeight={'bold'} fontSize={22} color={color[i]}>
										{dataList[i]}
									</Typography>
									<Typography color={'text.secondary'} fontSize={14}>
										{t(`dashboard.${i}`)}
									</Typography>
								</Card>
							</Grid>
						);
					})}
				</Grid>
			</SoftBox>
			<SoftBox mb={3}>
				<Card>
					<Grid container pt={2} px={2}>
						<Grid item xs={12} md={6}>
							<Stack direction={'row'}>
								<SoftTypography variant="h5" fontWeight="bold">
									{t('dashboard.Order history')}
									<Tooltip title={t('dashboard.Order rule')}>
										<Icon color="dark" fontSize="inherit">
											info
										</Icon>
									</Tooltip>
								</SoftTypography>
								&nbsp;
								<Button
									size="small"
									onClick={handleOverall}
									style={{ border: 'outset', color: '#e6280f' }}
									variant="outlined">
									{t('dashboard.overall')}
								</Button>
							</Stack>
						</Grid>
						<Grid item xs={12} md={6} marginTop={{ xs: 1, md: 0 }} display="flex" justifyContent={{ md: 'flex-end' }}>
							<SoftTypography variant="h6" fontWeight="bold">
								{t('inventory.ShipFrom')}:&nbsp;
								<ToggleButtonGroup
									color="primary"
									value={curType}
									exclusive
									onChange={handleCountry}
									aria-label="orderCountry"
									sx={{ borderColor: 'white' }}>
									{orderCountry.sort().map((label, index) => {
										let option = [];
										option.push(
											<ToggleButton key={index + label} value={label}>
												{label} {orderSet[label]}
											</ToggleButton>
										);
										return option;
									})}
								</ToggleButtonGroup>
							</SoftTypography>
						</Grid>
					</Grid>
					<Grid container pt={2} px={2}>
						<Grid item xs={12} md={7}>
							<TextField
								variant="outlined"
								value={searchItem}
								sx={{ width: { md: '70%', xs: '100%' } }}
								onChange={handleSearchChange}
								placeholder={t('dashboard.Search orderId, orderArea, orderBy, status')}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						{userType !== 'Z_Viewer' ? (
							<Grid
								item
								xs={12}
								md={5}
								paddingTop={{ xs: 2, md: 0 }}
								display="flex"
								justifyContent={{ md: 'flex-end' }}>
								<SoftButton color="primary" variant="outlined" size="small" onClick={handleMergeOpen}>
									{t('dashboard.Merge order')}
								</SoftButton>
								&nbsp;
								<SoftButton color="primary" variant="outlined" size="small" onClick={() => navigate(orderRoute)}>
									{t('dashboard.Create new order')}
								</SoftButton>
							</Grid>
						) : null}
					</Grid>
					<Divider />
					<SoftBox
						sx={{
							'& .MuiTableRow-root:not(:last-child)': {
								'& td': {
									borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`
								}
							}
						}}>
						{orderSet[curType] === undefined ? <NoCard /> : <DetailCard />}
					</SoftBox>
					<TablePagination
						component="div"
						rowsPerPageOptions={[10, 20, 30]}
						count={fOrders.length}
						page={page}
						onPageChange={handleChangePage}
						rowsPerPage={rowsPerPage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			</SoftBox>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				sx={{
					'& .css-1l52pf8-MuiButtonBase-root-MuiButton-root:hover': {
						bgcolor: '#adb2b3',
						color: '#0b0b0b'
					},
					'& .css-1l52pf8-MuiButtonBase-root-MuiButton-root': { color: '#0b0b0b' }
				}}>
				<DialogTitle id="alert-dialog-title" fontWeight="bold" align="center">
					{t('dashboard.This is going to delete the order.')}
				</DialogTitle>
				<DialogContent>
					<Box id="alert-dialog-description" align="center">
						<Typography fontSize={16}>{t('Are you sure to delete')}</Typography>
						{delVal.id ? (
							<Typography
								fontWeight={'bold'}
								fontSize={16}>{`${delVal.id.split('-')[0]} - ${delVal.orderarea}?`}</Typography>
						) : null}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} style={{ color: '#e6280f', borderColor: '#e6280f' }} variant="outlined">
						{t('Discard')}
					</Button>
					<Button onClick={deleteOrder} style={{ color: '#019923de', borderColor: '#019923de' }} variant="outlined">
						{t('Yes')}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={mergeOpen}
				onClose={handleMergeClose}
				aria-labelledby="alert-dialog-title-merge"
				aria-describedby="alert-dialog-description-merge"
				sx={{
					'& .css-1l52pf8-MuiButtonBase-root-MuiButton-root:hover': {
						bgcolor: '#adb2b3',
						color: '#0b0b0b'
					},
					'& .css-1l52pf8-MuiButtonBase-root-MuiButton-root': { color: '#0b0b0b' }
				}}
				scroll="paper"
				style={{ maxWidth: '100' }}>
				<DialogTitle id="alert-dialog-title-merge" fontWeight="bold" align="center">
					{t('dashboard.Please select orders to merge')}
				</DialogTitle>
				<DialogContent dividers={true}>
					<Box id="alert-dialog-description-merge" fontSize="16px" align="left" sx={{ height: '300px' }}>
						<Typography
							textAlign={'center'}
							fontSize={12}
							pb={1}
							color={'error'}
							sx={{ display: mergeOrders.length < 2 ? undefined : 'none' }}>
							{t('dashboard.Please select at least two orders.')}
						</Typography>
						<Autocomplete
							disablePortal
							options={areaOption}
							getOptionLabel={(option) => (typeof option === 'string' ? option : '')}
							onChange={(e, option) => getArea(option)}
							renderInput={(params) => <TextField {...params} placeholder={t('area.Please select the area')} />}
						/>
						{mergeDone ? (
							<Typography textAlign={'center'} pt={2}>
								{t('dashboard.Merge completed!')}
							</Typography>
						) : (
							mergeOptions.map((item) => (
								<Box padding={1}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													style={{ verticalAlign: 'baseline' }}
													name={item.id.split('-')[0]}
													onChange={(e) => getMerge(e, item)}
													checked={mergeID.indexOf(item.id.split('-')[0]) !== -1}
												/>
											}
											label={
												<>
													<Typography fontSize={14} fontWeight={'bold'}>
														{item.id.split('-')[0]}-{item.orderarea}-
														{t('dashboard.' + item.status[item.status.length - 1].split('@')[0])}
													</Typography>
													<Typography fontSize={14} color={'text.secondary'}>
														{t('dashboard.Order by')}-{item.orderby}
													</Typography>
												</>
											}
										/>
									</FormGroup>
								</Box>
							))
						)}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleMergeClose} variant="outlined" style={{ color: '#e6280f', borderColor: '#e6280f' }}>
						{t('Discard')}
					</Button>
					<Button
						onClick={MergeOrder}
						variant="outlined"
						style={{ color: '#019923de', borderColor: '#019923de' }}
						disabled={mergeOrders.length < 2 || loading}>
						{loading ? <CircularProgress size={12} color="success" /> : t('Confirm')}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={overallOpen}
				onClose={handleOverall}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" fontWeight="bold" textAlign="center">
					{t('dashboard.Orders by order area')}
				</DialogTitle>
				<DialogContent>
					<Overall />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleOverall} variant="outlined" style={{ color: '#e6280f', borderColor: '#e6280f' }}>
						{t('order.Back')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Dashboard;
