import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Audit from './audit/auditview';
import AuditForm from './audit/auditform';
import Prepcheck from './prepcheck/prepcheckview';
import PrepCheckForm from './prepcheck/prepcheckfom';
import SoftButton from '../../components/SoftButton';
import { audit, question, store } from '../../images/onlineForm/onlineFormImages';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Onlineform() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const forms = [
		{ title: 'Audit', image: audit, route: '/online_form/audit' },
		{ title: 'PrepCheck', image: store, route: '/online_form/prep' },
		{ title: 'pending', image: question, route: '' },
		{ title: 'pending', image: question, route: '' },
		{ title: 'pending', image: question, route: '' },
		{ title: 'pending', image: question, route: '' }
	];

	return (
		<Box>
			<Grid container>
				{forms.map((val, index) => (
					<Grid item md={4} key={val.title + index}>
						<Card sx={{ width: 300, height: 150, padding: 2, margin: 2 }}>
							<CardContent>
								<SoftButton
									onClick={() => navigate(val.route)}
									variant={'text'}
									style={{ position: 'relative', overflow: 'hidden', height: 80, width: 200, color: '#ce3719' }}
									disabled={val.title === 'pending'}>
									<Stack
										direction={'column'}
										justifyContent={'center'}
										alignItems={'center'}
										style={{ position: 'relative', zIndex: 1 }}>
										<Typography fontWeight={'bold'} color="#181818" bgcolor={'#ffffffbd'} fontSize={16}>
											{val.title === 'pending' ? '' : t('online_form.' + val.title)}
										</Typography>
									</Stack>
									<img
										src={val.image}
										alt={val.title}
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											objectFit: 'cover',
											zIndex: 0,
											opacity: 0.6
										}}
									/>
								</SoftButton>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
		</Box>
	);
}

export { Onlineform, Audit, AuditForm, Prepcheck, PrepCheckForm };
