import React, { useEffect, useState } from 'react'; //useCallback
import { useNavigate, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
// import { useAuthenticator } from '@aws-amplify/ui-react'; //, Flex, Heading,Text,StepperField
import PropTypes from 'prop-types';
import SoftButton from '../../components/SoftButton/index';
import SoftBox from '../../components/SoftBox';
import SoftTypography from '../../components/SoftTypography';
import SoftInput from '../../components/SoftInput';
import SoftBadge from '../../components/SoftBadge';
import Dialog from '../../components/Dialog';
import { exportToExcel } from '../../components/ExportExcel';
import { sendMail } from '../../scripts/lambda';
import { getCompany } from '../../scripts';
import { getOverseasOrder, listOverseasShippings } from '../../graphql/queries';
import { createOverseasOrder, updateOverseasOrder } from '../../graphql/mutations';
import Icon from '@mui/material/Icon';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import { Box, TextField, Autocomplete } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

const initialState = { code: [], name: '', qty: 0, amount: 0, status: [], orderType: 'TW' };
const steps = ['Create', 'Review', 'Done'];
Modal.setAppElement('#root');

const Order = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const [formState, updateFormState] = useState(initialState);
	const area = useSelector((state) => state.user.userArea);
	const getname = useSelector((state) => state.user.userName);
	// const email = useSelector((state) => state.user.userEmail);
	const role = useSelector((state) => state.user.userRole);
	const [inv, updateItem] = useState([]);
	const [qtyVal, updateVal] = useState([]);
	const [tabValue, setTabValue] = useState(0);
	const [activeStep, setActiveStep] = useState(0);
	const [curQty, setCurQty] = useState(0);
	const [curArea, setCurArea] = useState(area[0]);
	const [curCount, setCurCount] = useState(0);
	const [curCBM, setCBM] = useState(0);
	const [finalAmount, setFinalAmount] = useState(0);
	const [catCount, setCatCount] = useState({});
	const [catAmount, setCatAmount] = useState({});
	const [catQty, setCatQty] = useState({});
	const [currency, setCurrency] = useState('');
	const [input, setInput] = useState('');
	const [company, setCompany] = useState([]);
	const [companyEmail, setCompanyEmail] = useState([]);
	const [dialog, setDialog] = useState(false);
	const [dTitle, setDTitle] = useState('');
	const [dContent, setDContent] = useState('');

	useEffect(() => {
		if (location.state) {
			let stateArea = location.state[0].orderarea;
			setCurArea(stateArea);
			getItem(stateArea);
			if (stateArea === 'CA_Rosin-International-Enterprises-LTD') {
				setCurrency('TWD');
			} else {
				setCurrency('USD');
			}
		} else if (area.length > 0) {
			setCurArea(area[0]);
			getItem(area[0]);
			if (area[0] === 'CA_Rosin-International-Enterprises-LTD') {
				setCurrency('TWD');
			} else {
				setCurrency('USD');
			}
		}
		getCompany().then((i) => {
			let company = {};
			let email = {};
			Object.keys(i).map((key) =>
				!key.includes('/') ? (company[key] = i[key]) : key.includes('Email') ? (email[key.split('/')[0]] = i[key]) : ''
			);
			setCompany(company);
			setCompanyEmail(email);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [area]);

	const handleChange = (event, newArea) => {
		if (newArea !== null) {
			setCurArea(newArea);
			if (newArea === 'CA_Rosin-International-Enterprises-LTD') {
				setCurrency('TWD');
			} else {
				setCurrency('USD');
			}
			getItem(newArea);
		}
	};

	const handleDialog = (e, title, src) => {
		e.preventDefault();
		if (dialog) {
			setDialog(false);
			setDTitle('');
			setDContent('');
		} else if (title) {
			setDTitle(title);
			setDContent(
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<img src={src} width="70%" height="70%" alt={title} />
				</div>
			);
			setDialog(true);
		}
	};

	const handleSetTabValue = (event, newValue) => setTabValue(newValue);

	const handleNext = () => {
		if (activeStep === 0) {
			submit();
		}
		if (activeStep === 1) {
			createOrder();
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

	const handleInput = (e) => setInput(e.target.value);

	const handleReset = () => navigate('/dashboard');

	async function createOrder() {
		let created;
		if (location.state) {
			if (location.state.length > 0) {
				updateOrder(formState.code, formState.name, formState.qty, formState.amount, 'TW');
			}
		} else {
			try {
				const variables = {
					Code: formState.code,
					Name: formState.name,
					Qty: formState.qty,
					Amount: formState.amount,
					Status: formState.status,
					OrderType: 'TW'
				};
				created = await API.graphql({
					query: createOverseasOrder,
					variables: { input: variables }
				});
				created = created.data.createOverseasOrder;
			} catch (error) {
				console.log('error', error);
			}

			let data = [];
			let shipFrom = '';
			for (let i in inv) {
				let code = inv[i].Code;
				shipFrom = inv[i].ShipFrom;
				let iqty = qtyVal[i].Qty;
				let iprice = JSON.parse(inv[i].Price)[curArea];
				if (code === qtyVal[i].Code) {
					if (qtyVal[i].Qty > 0) {
						let cSize = inv[i].CartonSize ? inv[i].CartonSize.split('*') : 0;
						data.push({
							Category: inv[i].Category,
							ProductName: inv[i].Product,
							Code: inv[i].Code,
							UnitPrice: iprice,
							OrderingQty: iqty,
							Amount: Math.round(iprice * iqty * 100) / 100,
							ProductZH: inv[i].ZH,
							PackagingSize: inv[i].Size,
							ExpiryDate: inv[i].Expiry !== null ? inv[i].Expiry + 'months' : 'N/A',
							Unit: inv[i].Unit,
							NW: inv[i].NW,
							TotalNetWeight: inv[i].NW * parseInt(iqty) || '',
							GW: inv[i].GW,
							TotalGrossWeight: inv[i].GW * parseInt(iqty) || '',
							CBM: cSize === 0 ? '' : Math.round(((cSize[0] * cSize[1] * cSize[2] * iqty) / 1000000) * 100) / 100,
							CartonSize: inv[i].CartonSize,
							Note: inv[i].Note,
							OrderArea: curArea
						});
					}
				}
			}
			const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
			const fileExtension = '.xlsx';
			const borderStyle = { border: { bottom: { style: 'thick', color: '000000' } } };
			let sum = 0;
			qtyVal.map((item) => (item.Qty > 0 ? (sum += item.Price) : 0));
			exportToExcel(
				data,
				new Date().toISOString(),
				curQty,
				sum,
				company[curArea],
				fileType,
				fileExtension,
				borderStyle,
				'fileName',
				'buffer'
			).then(async (res) => {
				sendMail(
					getname,
					companyEmail[curArea],
					company[curArea],
					curQty,
					curCount,
					sum,
					curArea,
					qtyVal,
					shipFrom,
					res,
					created.id.split('-')[0],
					'create'
				);
			});
		}
		updateFormState(initialState);
	}

	async function getItem(areaVal) {
		let allItem = [];
		try {
			const variables = {
				limit: 500,
				filter: {
					ShipFrom: { eq: 'TW' },
					Area: { contains: areaVal }
				}
			};
			let hasNextPage = true;
			while (hasNextPage) {
				const { data: res } = await API.graphql({ query: listOverseasShippings, variables });
				const { items: item, nextToken } = res.listOverseasShippings;
				variables.nextToken = nextToken;
				allItem = allItem.concat(item);
				hasNextPage = nextToken ? true : false;
			}
			const posts = allItem;
			let userInv = [];
			let val = [];
			let oval = location.state;
			let index = -1;
			posts.sort(function (a, b) {
				if (a.Code < b.Code) {
					return -1;
				}
				if (a.Code > b.Code) {
					return 1;
				}
				return 0;
			});
			for (let i in posts) {
				if (posts[i] !== undefined) {
					if (posts[i].Area.includes(areaVal)) {
						val.push({
							id: posts[i].id,
							Code: posts[i].Code,
							Qty: 0,
							Price: JSON.parse(posts[i].Price)[areaVal],
							Product: posts[i].Product,
							Category: posts[i].Category,
							Expiry: posts[i].Expiry,
							Unit: posts[i].Unit,
							Size: posts[i].Size,
							ZH: posts[i].ZH,
							Moq: posts[i].Moq,
							Error: false,
							Nw: posts[i].NW,
							Gw: posts[i].GW,
							CBM: 0,
							CartonSize: posts[i].CartonSize || '0*0*0',
							Img: posts[i].Img,
							index: (index += 1)
						});
						userInv.push(posts[i]);
					}
				}
			}
			let newQt = 0;
			let newCount = 0;
			let newCbm = 0;
			let fa = 0;
			for (let o in oval) {
				for (let v in val) {
					if (val[v].Code === oval[o].Code) {
						let cSize = oval[o].CartonSize ? oval[o].CartonSize.split('*') : 0;
						val[v].Qty = parseInt(oval[o].OrderingQty);
						newQt += parseInt(val[v].Qty);
						newCount += 1;
						val[v].CBM =
							(parseInt(val[v].Qty) * (parseInt(cSize[0]) * parseInt(cSize[1]) * parseInt(cSize[2]))) / 1000000;
						newCbm += val[v].CBM || 0;
						val[v].Nw = oval[o].Nw * parseInt(val[v].Qty);
						val[v].Price = val[v].Qty * val[v].Price;
						fa += val[v].Price;
					}
				}
			}
			updateItem(userInv);
			updateVal(val);
			setCurQty(newQt);
			setCurCount(newCount);
			setFinalAmount(fa);
			setCBM(newCbm.toFixed(2));
			let cat = {};
			val
				.map((item) => item.Category)
				.map((item) => {
					return (cat[item] = 0);
				});
			val.map((item) => {
				if (item.Qty > 0) {
					cat[item.Category] += 1;
				}
				return '';
			});
			setCatCount(cat);

			let catQuantity = {};
			val
				.map((item) => item.Category)
				.map((item) => {
					return (catQuantity[item] = 0);
				});
			val.map((item) => {
				if (item.Qty > 0) {
					catQuantity[item.Category] += parseInt(item.Qty);
				}
				return '';
			});
			setCatQty(catQuantity);

			let catFa = {};
			val
				.map((item) => item.Category)
				.map((item) => {
					return (catFa[item] = 0);
				});
			val.map((item) => {
				if (item.Qty > 0) {
					catFa[item.Category] += item.Price;
				}

				return '';
			});
			setCatAmount(catFa);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	}

	function onChange(e, index) {
		let val = parseInt(e.target.value);
		let newVal = [...qtyVal];
		newVal[index].Price = val * JSON.parse(inv[index].Price)[curArea] || JSON.parse(inv[index].Price)[curArea];
		newVal[index].Qty = val || 0;
	}

	function onBlur(e, index, moq) {
		let newCount = 0;
		let catFa = {};
		let catQuantity = {};
		let newQty = 0;
		let val = parseInt(e.target.value) || 0;
		let min = moq[0];
		let max = moq[moq.length - 1];
		let newVal = [...qtyVal];
		let newPrice = JSON.parse(inv[index].Price)[curArea];
		let cat = {};
		let fa = 0;
		let newCbm = 0;
		let cSize = inv[index].CartonSize ? inv[index].CartonSize.split('*') : 0;
		newVal
			.map((item) => item.Category)
			.map((item) => {
				return (cat[item] = 0);
			});
		newVal
			.map((item) => item.Category)
			.map((item) => {
				return (catQuantity[item] = 0);
			});
		newVal
			.map((item) => item.Category)
			.map((item) => {
				return (catFa[item] = 0);
			});

		if (val > max || val < min) {
			newVal[index].Error = true;
			newVal[index].Price = newPrice;
			newVal[index].Qty = 0;
			newVal[index].CBM = 0;
		} else {
			newVal[index].Price = val * JSON.parse(inv[index].Price)[curArea] || newPrice;
			newVal[index].Qty = val;
			newVal[index].Error = false;
			newVal[index].CBM =
				parseInt(newVal[index].Qty * parseInt(cSize[0]) * parseInt(cSize[1]) * parseInt(cSize[2])) / 1000000;
		}

		qtyVal.map((item, i) => {
			if (item.Qty > 0 && item.Qty <= max) {
				cSize = item.CartonSize ? item.CartonSize.split('*') : 0;
				newQty += parseInt(item.Qty);
				newCount += 1;
				cat[item.Category] += 1;
				catQuantity[item.Category] += parseInt(item.Qty);
				catFa[item.Category] += item.Price;
				fa += item.Qty * JSON.parse(inv[i].Price)[curArea];
				newCbm += parseInt(item.Qty * parseInt(cSize[0]) * parseInt(cSize[1]) * parseInt(cSize[2])) / 1000000;
			}
			return newQty;
		});
		setCurQty(newQty);
		setCurCount(newCount);
		updateVal(newVal);
		setCatCount(cat);
		setCatQty(catQuantity);
		setCatAmount(catFa);
		setFinalAmount(fa);
		setCBM(newCbm.toFixed(2));
	}

	function CustomTabPanel(props) {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}>
				{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
			</div>
		);
	}

	CustomTabPanel.propTypes = {
		children: PropTypes.node,
		index: PropTypes.number.isRequired,
		value: PropTypes.number.isRequired
	};

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const OrderCard = (ival) =>
		[ival.val].map((item, index) => {
			const imgUrl = item.Img ? item.Img : 'products/img/5.png';
			const imgTitle = item.Img ? item.Product : 'Working on photo';
			const imgSrc = `https://macuglobal82751-staging.s3.ap-southeast-1.amazonaws.com/public/${imgUrl}`;
			return (
				<Card sx={{ border: '#eaeaea 0.1px solid' }} key={item.Product + index}>
					<Box
						sx={{ cursor: item.Img && 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						onClick={(e) => item.Img && handleDialog(e, imgTitle, imgSrc)}>
						<CardMedia sx={{ height: '20vh', width: '60%' }} image={imgSrc} title={imgTitle} />
					</Box>
					<CardContent sx={{ height: 200, paddingBottom: 0 }}>
						<Tooltip title={t('order.Code')}>
							<SoftTypography variant="caption" fontWeight="regular" color="text">
								<SoftBadge variant="gradient" badgeContent={item.Code} color="light" size="xs" container />
								&nbsp;
							</SoftTypography>
						</Tooltip>
						{item.Expiry > 0 ? (
							<Tooltip title={t('order.Expiry')}>
								<SoftTypography variant="caption" fontWeight="regular" color="text">
									<SoftBadge
										variant="gradient"
										badgeContent={item.Expiry + t('order.Month')}
										color="secondary"
										size="xs"
										container
									/>
									&nbsp;
								</SoftTypography>
							</Tooltip>
						) : null}
						{item.Unit ? (
							<Tooltip title={t('order.Unit')}>
								<SoftTypography variant="caption" fontWeight="regular" color="text">
									<SoftBadge variant="gradient" badgeContent={item.Unit} color="dark" size="xs" container />
								</SoftTypography>
							</Tooltip>
						) : null}
						<SoftTypography pt={2} variant="h6" fontWeight="medium">
							{item.Product.charAt(0) + item.Product.toLowerCase().slice(1)}
							<br />
							{item.ZH}
						</SoftTypography>
						<SoftTypography variant="h6" fontWeight="regular" color="text">
							{t('order.Size')}:&nbsp;{item.Size}
						</SoftTypography>
						<SoftTypography pt={2} variant="h6" fontWeight="medium">
							${item.Price.toLocaleString(undefined, { minimumFractionDigits: 2 })}
						</SoftTypography>
					</CardContent>
					<CardActions>
						<FormControl fullWidth variant="outlined" sx={{ paddingLeft: 2, paddingRight: 2 }}>
							{item.Moq.length > 2 ? (
								<>
									<Select
										defaultValue={item.Qty}
										onChange={(e) => onChange(e, ival.catindex)}
										onBlur={(e) => onBlur(e, ival.catindex, item.Moq)}>
										<MenuItem value={0}>0</MenuItem>
										{item.Moq.map((val) => {
											return (
												<MenuItem key={item.Product + val} value={val}>
													{val}
												</MenuItem>
											);
										})}
									</Select>
								</>
							) : (
								<>
									<SoftInput
										variant="standard"
										error={item.Error}
										type="number"
										defaultValue={item.Qty}
										id={item.id}
										onChange={(e) => onChange(e, ival.catindex)}
										onFocus={(e) => e.target.select()}
										onBlur={(e) => onBlur(e, ival.catindex, item.Moq)}
									/>
									<FormHelperText
										sx={{ marginLeft: 0 }}
										error={item.Error}
										id="outlined-weight-helper-msg"
										hidden={!item.Error}>
										{item.Moq[0] + ' <= ' + t('Quantity') + ' <= ' + item.Moq[item.Moq.length - 1]}
									</FormHelperText>
								</>
							)}
							<FormHelperText sx={{ marginLeft: 0 }} id="outlined-weight-helper-text">
								{t('order.Moq')} {item.Moq[0]} - {item.Moq[item.Moq.length - 1]}/{item.Unit}
							</FormHelperText>
						</FormControl>
					</CardActions>
				</Card>
			);
		});

	function ReviewCard() {
		let added = [];
		qtyVal.map((item) => {
			if (item.Qty > 0) {
				added.push(item);
			}
			return '';
		});
		return [...new Set(added.map((item) => item.Category))].map((category, catIndex) => (
			<div key={category + catIndex}>
				<SoftBox pt={2} px={2}>
					<Grid container spacing={3}>
						<Grid item xs>
							<SoftTypography variant="h6" fontWeight="bold">
								{category}
							</SoftTypography>
						</Grid>
						<Grid item xs>
							<SoftTypography variant="h6" fontWeight="bold">
								{catQty[category]}
							</SoftTypography>
						</Grid>
						<Grid item xs>
							<SoftTypography variant="h6" fontWeight="bold">
								${catAmount[category].toLocaleString(undefined, { minimumFractionDigits: 2 })}
							</SoftTypography>
						</Grid>
					</Grid>
				</SoftBox>
				<Divider />
				{added
					.filter((item) => item.Category === category)
					.map((item, index) => (
						<SoftBox
							component="li"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							key={item.Product + index}
							mb={false ? 0 : 1}
							pt={2}
							px={2}
							pb={2}>
							<Grid container spacing={3}>
								<Grid item xs>
									<SoftTypography display="block" variant="h6" fontWeight="regular">
										{item.Product.charAt(0) + item.Product.toLowerCase().slice(1)}
										<br />
										{item.ZH}
									</SoftTypography>
								</Grid>
								<Grid item xs>
									<SoftTypography variant="h6" fontWeight="regular">
										{item.Qty}
									</SoftTypography>
								</Grid>
								<Grid item xs>
									<SoftTypography variant="h6" fontWeight="regular">
										${item.Price.toLocaleString(undefined, { minimumFractionDigits: 2 })}
									</SoftTypography>
								</Grid>
							</Grid>
						</SoftBox>
					))}
			</div>
		));
	}

	function sumCard() {
		let sum = 0;
		qtyVal.map((item) => (item.Qty > 0 ? (sum += item.Price) : 0));
		return '$' + sum.toLocaleString(undefined, { minimumFractionDigits: 2 });
	}

	function submit() {
		let val = [];
		let userArea = getname + '@' + curArea;
		let qty = 0;
		let amount = 0;
		for (let q in qtyVal) {
			if (qtyVal[q].Qty > 0) {
				val.push(qtyVal[q].Code + '@' + qtyVal[q].Qty + '@' + parseFloat(qtyVal[q].Price.toFixed(2)));
				qty += parseInt(qtyVal[q].Qty);
				amount += qtyVal[q].Price; //parseFloat(qtyVal[q].Price.toFixed(2));
			}
		}
		updateFormState({
			code: val,
			name: userArea,
			qty: qty,
			amount: parseFloat(amount),
			status: ['pending@' + new Date().toISOString()]
		});
	}

	async function updateOrder(val, name, qty, amount) {
		try {
			const graph = await API.graphql({
				query: getOverseasOrder,
				variables: { id: location.state[0].orderid }
			});
			const original = graph.data.getOverseasOrder;
			const variables = {
				id: original.id,
				_version: original._version,
				Code: val,
				Name: name,
				Qty: qty,
				Amount: amount,
				Status: [...original.Status, 'pending@' + new Date().toISOString()]
			};
			await API.graphql({
				query: updateOverseasOrder,
				variables: { input: variables }
			});
		} catch (error) {
			console.log('error', error);
		}
		let shipFrom = '';
		let data = [];
		for (let i in inv) {
			let code = inv[i].Code;
			shipFrom = inv[i].ShipFrom;
			let iqty = qtyVal[i].Qty;
			let iprice = JSON.parse(inv[i].Price)[curArea];
			if (code === qtyVal[i].Code) {
				if (qtyVal[i].Qty > 0) {
					let cSize = inv[i].CartonSize ? inv[i].CartonSize.split('*') : 0;
					data.push({
						Category: inv[i].Category,
						ProductName: inv[i].Product,
						Code: inv[i].Code,
						UnitPrice: iprice,
						OrderingQty: iqty,
						Amount: Math.round(iprice * iqty * 100) / 100,
						ProductZH: inv[i].ZH,
						PackagingSize: inv[i].Size,
						ExpiryDate: inv[i].Expiry !== null ? inv[i].Expiry + 'months' : 'N/A',
						Unit: inv[i].Unit,
						NW: inv[i].NW,
						TotalNetWeight: inv[i].NW * parseInt(iqty) || '',
						GW: inv[i].GW,
						TotalGrossWeight: inv[i].GW * parseInt(iqty) || '',
						CBM: cSize === 0 ? '' : Math.round(((cSize[0] * cSize[1] * cSize[2] * iqty) / 1000000) * 100) / 100,
						CartonSize: inv[i].CartonSize,
						Note: inv[i].Note,
						OrderArea: curArea
					});
				}
			}
		}
		const fileType = 'application/vnd.openxmIformats-officedocument .spreadsheetml. sheet; charset-UTF-8';
		const fileExtension = '.xlsx';
		const borderStyle = { border: { bottom: { style: 'thick', color: '000000' } } };
		let sum = 0;
		qtyVal.map((item) => (item.Qty > 0 ? (sum += item.Price) : 0));
		exportToExcel(
			data,
			new Date().toISOString(),
			curQty,
			sum,
			company[curArea],
			fileType,
			fileExtension,
			borderStyle,
			'fileName',
			'buffer'
		).then(async (res) => {
			sendMail(
				getname,
				companyEmail[curArea],
				company[curArea],
				curQty,
				curCount,
				sum,
				curArea,
				qtyVal,
				shipFrom,
				res,
				location.state[0].orderid.split('-')[0],
				'update'
			);
		});
	}

	function FetchProducts(searchstring) {
		// eslint-disable-next-line
		const filteredList = qtyVal.filter((element) => {
			if (searchstring.val.length > 0) {
				for (let j in searchstring) {
					if (element.Product.includes(searchstring[j].toUpperCase())) {
						return element;
					}
				}
			}
		});
		return filteredList.map((item) => (
			<Grid item xs={12} md={4}>
				<OrderCard val={item} catindex={item.index} key={item + item.index} />
			</Grid>
		));
	}

	function searchTab() {
		return (
			<SoftBox className="App" spacing={3} sx={{ width: '100%' }}>
				<Autocomplete
					id="combo-box-demo"
					sx={{ width: 300 }}
					// defaultValue={input}
					options={qtyVal.map((item) => item.Product.charAt(0) + item.Product.toLowerCase().slice(1))}
					renderInput={(params) => (
						<TextField {...params} variant="outlined" placeholder="Search Item" onBlur={handleInput} />
					)}
				/>
				<Grid container spacing={2} paddingTop={2}>
					<FetchProducts val={input} />
				</Grid>
			</SoftBox>
		);
	}

	return (
		<>
			<SoftBox py={3}>
				<SoftBox mb={3}>
					<Card>
						<SoftBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
							<SoftTypography variant="h5" fontWeight="bold">
								{t('order.New order')}
							</SoftTypography>
							{location.state ? null : role !== 'Owner' && area.length > 1 ? (
								<Autocomplete
									disablePortal
									id="select-area"
									value={curArea}
									options={area.map((a) => a)}
									sx={{ width: 300 }}
									onChange={handleChange}
									renderInput={(params) => <TextField {...params} placeholder={t('area.Please select the area')} />}
								/>
							) : null}
						</SoftBox>
						<SoftBox
							sx={{
								'& .MuiTableRow-root:not(:last-child)': {
									'& td': {
										borderBottom: ({ borders: { borderWidth, borderColor } }) =>
											`${borderWidth[1]} solid ${borderColor}`
									}
								}
							}}>
							<Stepper activeStep={activeStep}>
								{steps.map((label) => {
									const stepProps = {};
									const labelProps = {};
									return (
										<Step key={label} {...stepProps}>
											<StepLabel {...labelProps}>{t('order.' + label)}</StepLabel>
										</Step>
									);
								})}
							</Stepper>
							<SoftTypography px={2} variant="h6" fontWeight="bold">
								{' '}
								{t('order.Currency')}: {currency}, CBM: {curCBM}
							</SoftTypography>
							{(() => {
								if (activeStep === steps.length - 1) {
									return (
										<div>
											<SoftBox p={2} mx={3} display="flex" justifyContent="center">
												<SoftBox
													display="grid"
													justifyContent="center"
													alignItems="center"
													color="green"
													width="4rem"
													height="4rem">
													<Icon fontSize="large">check_circle</Icon>
												</SoftBox>
											</SoftBox>
											<SoftBox pb={6} px={2} pt={6} textAlign="center" lineHeight={1.25}>
												<SoftTypography variant="h6" fontWeight="bold">
													{t('order.Done')}
												</SoftTypography>
												<br />
												<SoftTypography variant="h6" color="text" fontWeight="bold">
													{t('order.Order created')}
												</SoftTypography>
												<SoftTypography variant="caption" color="text" fontWeight="regular">
													{t('Order Created')}
												</SoftTypography>
												<Divider />
												<SoftButton onClick={handleReset}>{t('order.View order')}</SoftButton>
											</SoftBox>
										</div>
									);
								} else if (activeStep === 0) {
									return (
										<div>
											<SoftBox pt={2} px={2}>
												<Grid container spacing={3}>
													<Grid item xs={12} md={10}>
														<SoftTypography variant="h6" color="text" fontWeight="bold">
															{t('Item')}: {curCount}, {t('order.Total quantity')}: {curQty.toLocaleString()},{' '}
															{t('Total amount')}: $
															{finalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
														</SoftTypography>
													</Grid>
													<Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
														<SoftButton
															variant="contained"
															color="secondary"
															onClick={handleNext}
															disabled={curQty === 0}>
															{t('order.Next')}
														</SoftButton>
													</Grid>
												</Grid>
											</SoftBox>
											<SoftBox>
												<Tabs
													value={tabValue}
													onChange={handleSetTabValue}
													variant="scrollable"
													scrollButtons
													allowScrollButtonsMobile>
													<Tab
														icon={<SearchIcon />}
														iconPosition="end"
														label="Search"
														fontSize="large"
														key={'searchbtn'}
														{...a11yProps(0)}
													/>
													{[...new Set(qtyVal.map((item) => item.Category))].map((item, index) => (
														<Tab
															label={
																<span>
																	{item}&nbsp;
																	{catCount[item] > 0 ? (
																		<SoftBadge
																			variant="gradient"
																			circular
																			badgeContent={catCount[item]}
																			color="light"
																			size="xs"
																			container
																		/>
																	) : null}
																</span>
															}
															key={item + 'fbtn'}
															{...a11yProps(index)}
														/>
													))}
												</Tabs>
											</SoftBox>
											<CustomTabPanel value={tabValue} index={0} key={'panel-search'}>
												{searchTab()}
											</CustomTabPanel>
											<Grid container>
												{qtyVal.map((cat, catindex) =>
													[cat]
														.filter(
															(item) =>
																item.Category === [...new Set(qtyVal.map((item) => item.Category))][tabValue - 1]
														)
														.map((item, index) => (
															<Grid item xs={12} md={4} key={item + 'cat' + index}>
																<CustomTabPanel value={tabValue} index={tabValue} key={item + 'panel' + index}>
																	<OrderCard val={item} catindex={catindex} />
																</CustomTabPanel>
															</Grid>
														))
												)}
											</Grid>
										</div>
									);
								} else {
									return (
										<div>
											<Box
												component="li"
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												mb={false ? 0 : 1}
												pt={2}
												px={2}>
												<SoftButton onClick={handleBack} sx={{ mr: 1 }}>
													{t('order.Back')}
												</SoftButton>
												<Box sx={{ flex: '1 1 auto' }} />
												<SoftButton variant="contained" color="success" onClick={handleNext}>
													{location.state ? t('order.Update') : t('order.Create')}
												</SoftButton>
											</Box>
											<SoftBox
												component="li"
												display="flex"
												justifyContent="space-between"
												alignItems="center"
												mb={false ? 0 : 1}
												pt={2}
												px={2}>
												<Grid container spacing={3}>
													<Grid item xs>
														<SoftTypography display="block" variant="h6" fontWeight="bold">
															{t('Item')} ({curCount})
														</SoftTypography>
													</Grid>
													<Grid item xs>
														<SoftTypography variant="h6" fontWeight="bold">
															{t('Quantity')} ({curQty})
														</SoftTypography>
													</Grid>
													<Grid item xs>
														<SoftTypography variant="h6" fontWeight="bold">
															{t('Price')} ({sumCard()})
														</SoftTypography>
													</Grid>
												</Grid>
											</SoftBox>
											<ReviewCard />
										</div>
									);
								}
							})()}
						</SoftBox>
					</Card>
				</SoftBox>
			</SoftBox>
			<Dialog dialog={dialog} title={dTitle} content={dContent} handleClose={handleDialog} showBtn={false} />
		</>
	);
};

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// };

export default Order;
