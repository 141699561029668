import { InvokeCommand } from '@aws-sdk/client-lambda';
import lambdaClient from './lambdaClient';
import moment from 'moment';

export const sendMail = async (
	getname,
	email,
	company,
	curQty,
	curCount,
	sum,
	curArea,
	qtyVal,
	shipFrom,
	res,
	id,
	action
) => {
	const originalPath = window.location.origin;
	const input = {
		// InvocationRequest
		FunctionName: 'mailer-staging', // required
		Payload: JSON.stringify({
			buyer: getname,
			buyer_email: email,
			company: company,
			quantity: curQty,
			amount: sum,
			item: curCount,
			date: moment().format('L'),
			time: moment().format('LTS'),
			area: curArea,
			table: qtyVal,
			country: shipFrom,
			content: res === '' ? res : Object.values(new Uint8Array(res)),
			orderId: id,
			action: action,
			path: originalPath
		})
	};
	const command = new InvokeCommand(input);
	try {
		await lambdaClient.send(command);
	} catch (error) {
		console.log('Error', error);
	}
};

export const createUser = async (data) => {
	const input = {
		FunctionName: 'CreateUser',
		Payload: JSON.stringify({
			data: data
		})
	};
	const command = new InvokeCommand(input);
	try {
		const res = await lambdaClient.send(command);
		console.log('res', res);
	} catch (error) {
		console.log('Error', error);
	}
};
