import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Storage } from '@aws-amplify/storage';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
	fetchAllStore,
	fetchPrepCheckContent,
	uploadPrepAttach,
	// deletePrepAttach,
	createPrepCheck,
	downloadBlob
} from '../../../scripts'; //sleep,
import Signature from '../../../components/Signature';
import SoftButton from '../../../components/SoftButton';
// import SoftButton from '../../../components/SoftButton/index';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const fontSizeStyle = {
	fontSize: 14
};

function PrepCheckForm() {
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const check = location.state.route;
	const disable = location.state.action === 'view' ? true : false;
	const [startPickerOn, setStartPickerOn] = useState(false);
	const [endPickerOn, setEndPickerOn] = useState(false);
	const [formData, setFormData] = useState([]);
	const [imageURL, setImageURL] = useState(check ? check.OwnerSign : null);
	const [imageURL2, setImageURL2] = useState(check ? check.StaffSign : null);
	const [storeData, setStoreData] = useState([]);
	const [headerData, setHeaderData] = useState({
		start: check ? check.S_Date : moment().format('MM/DD/YYYY'),
		end: check ? check.E_Date : moment().add(1, 'M').format('MM/DD/YYYY'),
		store: check ? check.Store : '',
		owner: '',
		supervisor: check ? check.Supervisor : '',
		suggestion: check ? check.Suggestion : ''
	});
	const [storeOwner, setStoreOwner] = useState('');
	const [submitLoading, setSubmitLoading] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [ogFile, setOgFile] = useState([]);
	const fileInputRefs = useRef([]);
	const attachIndex = [18, 19, 20, 23, 24, 25];

	const findOldVal = (key, oldVal, val) => oldVal.filter((value) => value.id === val.id)[0][key];

	const getContent = async () => {
		try {
			const allstore = await fetchAllStore();
			const tidyup = allstore.map((item) => ({ store: item.Store, owner: item.Owner }));
			setStoreData(tidyup);
			if (check) {
				setStoreOwner(tidyup.find((option) => option.store.includes(check.Store)).owner);
				handleHeaderInputChange('owner', tidyup.find((option) => option.store.includes(check.Store)).owner);
			}
			const prepcontent = await fetchPrepCheckContent();
			prepcontent.sort((a, b) => a.Number - b.Number);
			let oldVal = [];
			if (check) {
				oldVal = check.Content.map((item) => JSON.parse(item));
			}
			let oldFiles = [];
			prepcontent.map((item, index) => {
				let val = item;
				val.completed = check ? findOldVal('completed', oldVal, val) : false;
				val.notes = check ? findOldVal('notes', oldVal, val) : null;
				val.files = [];
				if (attachIndex.includes(index) && check) {
					if (oldVal[index].attachments.length > 0) {
						oldVal[index].attachments.map((i) => oldFiles.push({ number: index, key: i.key }));
						val.files = findOldVal('attachments', oldVal, val);
					}
				}
				return val;
			});
			setOgFile(oldFiles);
			setFormData(prepcontent);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getContent();
	}, []);

	const back = () => navigate('/online_form/prep');

	const handleHeaderInputChange = (field, value) => setHeaderData((prev) => ({ ...prev, [field]: value }));

	const handleDateChange = (key, newValue) => handleHeaderInputChange(key, moment(newValue).format('MM/DD/YYYY'));

	const handleStoreChange = (e) => {
		const value = e.target.value;
		const label = storeData.find((option) => option.store.includes(value));
		if (label) {
			handleHeaderInputChange('owner', label.owner);
		}
		handleHeaderInputChange('store', value);
		setStoreOwner(label.owner);
	};

	const handleInputChange = (index, field, value) => {
		const newData = [...formData];
		newData[index][field] = value;
		setFormData(newData);
	};

	const handleFileUpload = (event, index) => {
		const files = Array.from(event.target.files);
		if (files.length > 0) {
			const newData = [...formData];
			newData[index].files = [...newData[index].files, ...files];
			setFormData(newData);
		}
	};

	const handleClearFiles = (index, i) => {
		const newData = [...formData];
		newData[index].files.splice(i, 1);
		setFormData(newData);
		if (fileInputRefs.current[index]) {
			fileInputRefs.current[index].value = '';
		}
	};

	const downloadAttachment = async (i) => {
		const result = await Storage.get(i, { download: true });
		downloadBlob(result.Body, i);
	};

	const downloadAll = async (file, folderName) => {
		const zip = new JSZip();
		for (const f of file) {
			const result = await Storage.get(f.key, { download: true });
			const fileName = f.key.split('/')[3].substring(14) || 'download';
			zip.file(fileName, result.Body);
		}

		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `${folderName}.zip`);
		});
	};

	const handleSubmit = (status) => {
		status === 'submitted' ? setSubmitLoading(true) : setSaveLoading(true);
		let fileCounts = 0;
		attachIndex.map((i) => (fileCounts += formData[i].files.length));
		const formVal = formData.map(async (item, index) => {
			const val = { id: item.id, completed: item.completed ? true : false };
			if (item.notes) {
				val.notes = item.notes;
			}
			if (attachIndex.includes(index)) {
				const curFileWithKeys = item.files.filter((i) => i.key !== undefined);
				val.attachments = curFileWithKeys; // store old files, no files is []
				let newFile = item.files.filter((f) => f.key === undefined); // get files to upload
				let curFileKeys = curFileWithKeys.map((k) => k.key);
				let removeFiles = ogFile.filter((o) => o.number === index && !curFileKeys.includes(o.key)); // get files to remove
				if (removeFiles.length > 0) {
					// await deletePrepAttach(removeFiles); // currently turned off
				}
				if (newFile.length > 0) {
					if (check) {
						const res = await uploadPrepAttach(newFile, headerData.owner, headerData.store, moment().valueOf());
						res.map((r) => val.attachments.push(r)); // store new files
					} else {
						const res = await uploadPrepAttach(item.files, headerData.owner, headerData.store, moment().valueOf());
						val.attachments = res;
					}
				}
			}
			return JSON.stringify(val);
		});
		Promise.all(formVal)
			.then(async (res) => {
				const create = {
					S_Date: headerData.start,
					E_Date: headerData.end,
					Year: moment(headerData.start).format('YYYY'),
					Month: moment(headerData.start).format('MM'),
					Store: headerData.store,
					Supervisor: headerData.supervisor,
					Content: res,
					Suggestion: headerData.suggestion,
					Status: [status + '@' + moment().toISOString()],
					Author: headerData.supervisor,
					OwnerSign: imageURL,
					StaffSign: imageURL2
				};
				await createPrepCheck(check, create);
			})
			.catch((error) => console.log('error', error));
		setTimeout(() => {
			status === 'submitted' ? setSubmitLoading(false) : setSaveLoading(false);
			back();
		}, 3000);
	};

	const cellStyle = {
		height: '100px',
		padding: '6px 14px',
		verticalAlign: 'middle',
		fontSize: 14
	};

	const headerStyle = {
		fontWeight: 'bold',
		fontSize: 16
	};

	const StoreDropdown = () =>
		check ? (
			check.Store
		) : (
			<FormControl sx={{ minWidth: 190, alignSelf: 'start' }}>
				<Select
					native
					defaultValue={check ? check.Store : ''}
					id="grouped-native-select"
					onChange={handleStoreChange}
					disabled={disable}>
					<option aria-label="None" value="" />
					{storeData.map((item) => (
						<optgroup label={item.owner} key={item.store.toString()}>
							{item.store.map((name) => (
								<option value={name}>{name}</option>
							))}
						</optgroup>
					))}
				</Select>
			</FormControl>
		);

	const shortenName = (name) => (name.length > 8 ? name.substring(0, 8) + '...' : name);

	return (
		<Box component="form" onSubmit={handleSubmit}>
			<Typography align="left" paddingBottom={2}>
				<SoftButton variant="contained" onClick={back} color={'light'} size="small">
					{t('Back')}
				</SoftButton>
			</Typography>
			<TableContainer sx={{ mb: 4 }} component={Paper}>
				<Table sx={{ '& .MuiTableHead-root': { display: 'table-header-group' }, minWidth: { md: 650, xs: 730 } }}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={6} align="left">
								<Grid container>
									<Grid item md={3} xs={3}>
										<Chip
											variant="outlined"
											icon={disable ? <RemoveRedEyeIcon /> : <EditIcon />}
											label={disable ? t('auditForm.view') : t('auditForm.edit')}
											sx={{
												color: disable ? '#f14a32' : '#039c19',
												'& .MuiChip-icon': { color: disable ? '#f14a32' : '#039c19' }
											}}
										/>
									</Grid>
									<Grid item md={9} xs={9} sx={{ fontWeight: 'bold', fontSize: 16 }}>
										Trainer Local Store Preparation Check List 培訓師當地培訓追蹤清單
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} sx={headerStyle}>
								{t('prep.' + 'Date')}
							</TableCell>
							<TableCell colSpan={4}>
								<DatePicker
									label={t('prep.Start')}
									open={startPickerOn}
									value={moment(headerData.start)}
									onChange={(newValue) => handleDateChange('start', newValue)}
									onAccept={() => setEndPickerOn(true)}
									onOpen={() => setStartPickerOn(true)}
									onClose={() => setStartPickerOn(false)}
									disabled={disable}
									sx={{ backgroundColor: 'white' }}
									slotProps={{
										textField: {
											onClick: () => setStartPickerOn(true)
										}
									}}
								/>
								<DatePicker
									label={t('prep.End')}
									open={endPickerOn}
									value={moment(headerData.end)}
									minDate={moment(headerData.start)}
									onChange={(newValue) => handleDateChange('end', newValue)}
									onOpen={() => setEndPickerOn(true)}
									onClose={() => setEndPickerOn(false)}
									disabled={disable}
									sx={{ backgroundColor: 'white', marginLeft: 3 }}
									slotProps={{
										textField: {
											onClick: () => setEndPickerOn(true)
										}
									}}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} sx={headerStyle}>
								{t('prep.' + 'Store') + ':'}
							</TableCell>
							<TableCell colSpan={4}>
								<Grid container>
									<Grid item xs={3} md={4}>
										{StoreDropdown()}
									</Grid>
									<Grid item xs={9} md={8} display="flex" alignItems="center" sx={headerStyle}>
										{t('prep.' + 'Owner')}
										{storeOwner}
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} sx={headerStyle}>
								{t('prep.' + 'Supervisor') + ':'}
							</TableCell>
							<TableCell colSpan={4}>
								<TextField
									value={headerData['supervisor']}
									onChange={(e) => handleHeaderInputChange('supervisor', e.target.value)}
									disabled={disable}
									variant="outlined"
									size="small"
								/>
							</TableCell>
						</TableRow>
						<TableRow sx={{ bgcolor: '#e1ebfd' }}>
							<TableCell sx={{ width: '2%', ...headerStyle, textAlign: 'center' }}>{t('prep.No.')}</TableCell>
							<TableCell sx={{ width: '10%', ...headerStyle }}>{t('prep.Category')}</TableCell>
							<TableCell sx={{ width: '25%', ...headerStyle }}>{t('prep.Content')}</TableCell>
							<TableCell sx={{ width: '3%', ...headerStyle }}>{t('prep.Completed')}</TableCell>
							<TableCell sx={{ width: '20%', ...headerStyle }}>{t('prep.Notes')}</TableCell>
							<TableCell sx={{ width: '10%', ...headerStyle }}>{t('prep.Attach')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{formData.map((row, index) => (
							<TableRow key={index}>
								<TableCell sx={{ ...cellStyle, textAlign: 'center' }}>{row.Number}</TableCell>
								<TableCell sx={{ ...cellStyle, whiteSpace: 'pre-line' }}>{row.Category}</TableCell>
								<TableCell sx={cellStyle}>
									{row.Content} <br /> {row.ZH}
								</TableCell>
								<TableCell sx={cellStyle} align="center">
									<Checkbox
										checked={row.completed}
										onChange={(e) => handleInputChange(index, 'completed', e.target.checked)}
										disabled={disable}
									/>
								</TableCell>
								<TableCell sx={cellStyle}>
									<TextField
										fullWidth
										multiline
										rows={2}
										value={row.notes}
										onChange={(e) => handleInputChange(index, 'notes', e.target.value)}
										disabled={disable}
										placeholder={t('prep.Enter notes')}
										variant="outlined"
										size="small"
									/>
								</TableCell>
								<TableCell sx={cellStyle}>
									<Grid container>
										<Grid item md={3} display={'inline-grid'} alignItems={'center'}>
											{attachIndex.includes(index) &&
												(disable && row.files.length > 0 ? (
													<Tooltip title={t('prep.download all')}>
														<IconButton color="dark" size="small" onClick={() => downloadAll(row.files, row.Category)}>
															<SaveAltIcon />
														</IconButton>
													</Tooltip>
												) : (
													<>
														<input
															accept="*/*"
															type="file"
															id={`file-upload-${index}`}
															multiple
															disabled={disable}
															onChange={(e) => handleFileUpload(e, index)}
															ref={(el) => (fileInputRefs.current[index] = el)}
															style={{ display: 'none' }}
														/>
														<label htmlFor={`file-upload-${index}`}>
															<Tooltip title={t('prep.upload')}>
																<IconButton
																	color="primary"
																	component="span"
																	size="small"
																	sx={{ display: disable ? 'none' : undefined }}>
																	<CreateNewFolderIcon />
																</IconButton>
															</Tooltip>
														</label>
													</>
												))}
										</Grid>
										<Grid item md={9}>
											{attachIndex.includes(index) && row.files.length > 0
												? row.files.map((f, i) => (
														<Stack direction={'row'} alignItems={'center'}>
															<Tooltip title={f.name}>
																<Typography fontSize={12}>{shortenName(f.name)}</Typography>
															</Tooltip>
															{disable ? (
																<IconButton color="success" size="small" onClick={() => downloadAttachment(f.key)}>
																	<DownloadIcon />
																</IconButton>
															) : (
																<IconButton color="secondary" size="small" onClick={() => handleClearFiles(index, i)}>
																	<ClearIcon />
																</IconButton>
															)}
														</Stack>
													))
												: null}
										</Grid>
									</Grid>
								</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell colSpan={6} align={'center'}>
								<Typography sx={headerStyle}>{t('prep.Suggestion')}</Typography>
								<TextField
									multiline
									fullWidth
									rows={6}
									defaultValue={headerData.suggestion}
									onChange={(e) => handleHeaderInputChange('suggestion', e.target.value)}
									disabled={disable}></TextField>
							</TableCell>
						</TableRow>
					</TableBody>
					<TableRow>
						<TableCell colSpan={3} align={'center'}>
							<Typography sx={headerStyle}>{t('prep.Owner Signature')}</Typography>
							<Signature disable={disable} signURL={imageURL} handleSignatureDone={(arg) => setImageURL(arg)} />
						</TableCell>
						<TableCell colSpan={3} align={'center'}>
							<Typography sx={headerStyle}>{t('prep.Supervisor Signature')}</Typography>
							<Signature disable={disable} signURL={imageURL2} handleSignatureDone={(arg) => setImageURL2(arg)} />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell colSpan={6} align={'center'}>
							<ButtonGroup size="small" disabled={disable} variant="contained" aria-label="Platform">
								<Button
									value="放棄"
									style={{ backgroundColor: '#f14a32', ...fontSizeStyle }}
									onClick={back}
									startIcon={<DeleteForeverIcon />}>
									{t('auditForm.Discard')}
								</Button>
								<Button
									value="儲存"
									style={{ backgroundColor: '#d4d4d4de' }}
									onClick={() => handleSubmit('working')}
									startIcon={<SaveIcon />}
									disabled={!headerData.store}>
									<Typography sx={{ display: !saveLoading ? undefined : 'none', ...fontSizeStyle }}>
										{t('auditForm.Save')}
									</Typography>
									<CircularProgress size={24} sx={{ color: 'white', display: saveLoading ? undefined : 'none' }} />
								</Button>
								<Button
									value="提交"
									style={{ backgroundColor: '#039c19' }}
									onClick={() => handleSubmit('submitted')}
									startIcon={<CloudUploadIcon />}
									disabled={!(headerData.store && headerData.supervisor && headerData.start && imageURL && imageURL2)}>
									<Typography sx={{ display: !submitLoading ? undefined : 'none', ...fontSizeStyle }}>
										{t('auditForm.Submit')}
									</Typography>
									<CircularProgress size={24} sx={{ color: 'white', display: submitLoading ? undefined : 'none' }} />
								</Button>
							</ButtonGroup>
						</TableCell>
					</TableRow>
				</Table>
			</TableContainer>
		</Box>
	);
}

export default PrepCheckForm;
