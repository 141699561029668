import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getAllAuditLists } from '../../../scripts';
import Viewtable from '../viewtable';
import SoftButton from '../../../components/SoftButton';
import Box from '@mui/material/Box';

export default function Audit() {
	const { userRole, userStore } = useSelector((state) => state.user);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [sortedRows, setSortedRows] = useState([]);
	const [storeVal, setStoreVal] = useState([]);
	const [loading, setLoading] = useState(true);
	const formRoute = '/online_form/audit/edit';
	const columns = [
		{ field: 'Action', headerName: '操作', width: 120 },
		{ field: 'Status', headerName: '狀態', width: 90 },
		{ field: 'Store', headerName: '門市', width: 120 },
		{ field: 'Date', headerName: '建立日期', width: 120 },
		{ field: 'Model', headerName: '評核模式', width: 150 },
		{ field: 'Type', headerName: '評核類別', width: 120 },
		{ field: 'Final', headerName: '總分', width: 90 },
		{ field: 'Suggestion', headerName: '本次建議事項', width: 150 },
		{ field: 'Improvement', headerName: '待改善事項', width: 150 },
		{ field: 'Author', headerName: '填表人', width: 100 }
	];

	async function getForms(from, to) {
		getAllAuditLists(userStore, moment(from).format('MM/DD/YYYY'), moment(to).format('MM/DD/YYYY')).then((res) => {
			let form = res;
			let storeV = [];
			let formsCount = {};
			let formScore = {};
			let content = res.map((p) => p.Content.map((c) => JSON.parse(c)));
			form = form.filter((f, index) => {
				f.Date = moment(f.Date).format('L');
				f.Status = f.Status[f.Status.length - 1].split('@')[0];
				let checkState = !(f.Date < moment().subtract(2, 'day').format('L') && f.Status === 'working'); //remove working status for more than 3 days
				f.Content = content[index];
				if (checkState && f.Status === 'submitted') {
					formsCount[f.Store] = formsCount[f.Store] ? formsCount[f.Store] + 1 : 1;
					formScore[f.Store] = formScore[f.Store] ? (formScore[f.Store] += f.Final) : f.Final;
				}
				return checkState;
			});
			// form = form.map((f) => f).filter((item) => (storeNames.includes('總部') ? item.Status === '提交' : storeNames.includes(item.Store)));
			//check visibility //!storeName.includes('總部')

			form.sort((a, b) => {
				let store = a['Store'].localeCompare(b['Store']);
				return store !== 0 ? store : a['Date'].localeCompare(b['Date']);
			});

			Object.keys(formsCount).map((f) =>
				storeV.push({ store: f, count: formsCount[f], score: Math.round((formScore[f] / formsCount[f]) * 100) / 100 })
			); //get store statistics
			setStoreVal(storeV);
			setSortedRows(form);
			setLoading(false);
		});
	}

	return (
		<Box>
			<Box pb={2}>
				<SoftButton variant="contained" color="light" size="small" onClick={() => navigate('/online_form')}>
					{t('Back')}
				</SoftButton>
			</Box>
			<Viewtable
				getForms={getForms}
				sortedRows={sortedRows}
				storeVal={storeVal}
				columns={columns}
				hasDialog={true}
				formRoute={formRoute}
				isStaff={userRole !== 'Owner'}
				loading={loading}
			/>
		</Box>
	);
}
