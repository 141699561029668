import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Amplify, API } from 'aws-amplify';
import { withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import Sidenav from './template/Sidenav';
import DashboardLayout from './template/LayoutContainers/DashboardLayout';
import DashboardNavbar from './template/Navbars/DashboardNavbar';
import { listOverseasStores } from './graphql/queries';
import { listPermission } from './scripts';
import theme from './assets/theme';
import './assets/theme/styles.css';
import routes from './routes';
import Home from './pages/home/home';
import { useSoftUIController, setMiniSidenav } from './context';
import { getArea, getName, getType, getRole, getEmail, getStore, getZone, getUserSettings } from './Redux/userSlice';
import awsExports from './aws-exports';
import brand from './assets/images/MACU_Logo_Red_NOT.svg';
import Header from './pages/authentication/header';
import Footer from './pages/authentication/footer';
import ScrollTop from './components/BackToTop/index';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
Amplify.configure(awsExports);

const App = () => {
	const [controller, dispatch] = useSoftUIController();
	const { miniSidenav, direction, layout, sidenavColor } = controller; //openConfigurator
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const [userSettings, setUsersettings] = useState([]);
	const { pathname } = useLocation();
	const disPatch = useDispatch();
	const { user } = useAuthenticator((context) => [context.user]);
	const area = user.attributes.address.split(',');
	const userType = user.attributes.zoneinfo.split(',').sort();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	const userInfo = () => {
		disPatch(getArea(area));
		disPatch(getName(user.attributes.name));
		disPatch(getType(userType[userType.length - 1]));
		disPatch(getRole(userType[0] === 'Owner' ? 'Owner' : userType.includes('Y_TW') ? 'TW_staff' : 'US_staff'));
		disPatch(getEmail(user.attributes.email));
		disPatch(getZone(userType));
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Setting the dir attribute for the body element
	useEffect(() => {
		userInfo();
		storeList();
		document.body.setAttribute('dir', direction);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	async function storeList() {
		let data = [];
		let list = [];
		try {
			let result = await API.graphql({
				query: listOverseasStores
			});
			result = result.data.listOverseasStores.items;
			result.map((r) =>
				data.push({ Area: r.Country + '_' + r.Company.replaceAll('& ', '').replaceAll(' ', '-'), Store: r.Store })
			);
			let filter = data.filter((f) => area.includes(f.Area));
			filter.map((f) => (list = list.concat(f.Store)));
			disPatch(getStore(list));
			const res = await listPermission();
			disPatch(getUserSettings(res));
			setUsersettings(res);
		} catch (error) {
			console.log('error', error);
		}
	}

	const getRoutes = (allRoutes) => {
		let types = JSON.parse(JSON.stringify(userType));
		types.splice(types.indexOf('Y_TW'), 1);
		return allRoutes.map((route) => {
			let show = true;
			const curLvl = userSettings.filter((item) => item.route === route.route);
			const userName = user.attributes.name;
			let checkKeys = userType.includes('Y_TW') && curLvl[0].TW_View === false ? types : userType;
			checkKeys.map((type) => {
				if (!curLvl[0].permission[type].includes(userName)) {
					show = false;
				}
			});
			if (route.route && show) {
				if (route.collapse) {
					return getRoutes(route.collapse);
				}
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}
		});
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{layout === 'dashboard' && userSettings.length > 0 && (
					<Sidenav
						color={sidenavColor}
						brand={brand}
						brandName="MACU TEA"
						routes={routes}
						userSettings={userSettings}
						user={user}
						userType={userType}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
				)}
				<div id="back-to-top-anchor" />
				<ScrollTop />
				<DashboardLayout>
					<DashboardNavbar />
					<Routes>
						{userSettings.length > 0 && getRoutes(routes)}
						{userSettings.length > 0 && (
							<Route
								path="*"
								element={<Home userSettings={userSettings} userType={userType} userName={user.attributes.name} />}
							/>
						)}
					</Routes>
				</DashboardLayout>
			</ThemeProvider>
		</LocalizationProvider>
	);
};

export default withAuthenticator(App, {
	hideSignUp: true,
	components: {
		Header,
		Footer
	}
});
