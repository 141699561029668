import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { store, user, userCreated } from '../../assets/images/index';
import { listArea } from '../../scripts';
import SoftInput from '../../components/SoftInput';
import SoftButton from '../../components/SoftButton';
import { createUser } from '../../scripts/lambda';
import { createOverseasStore } from '../../graphql/mutations';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const images = [
	{
		url: store,
		title: 'New Store',
		width: '30%'
	},
	{
		url: user,
		title: 'New User',
		width: '30%'
	}
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
	position: 'relative',
	height: 200,
	[theme.breakpoints.down('sm')]: {
		width: '100% !important', // Overrides inline-style
		height: 100
	},
	'&:hover, &.Mui-focusVisible': {
		zIndex: 1,
		'& .MuiImageBackdrop-root': {
			opacity: 0.15
		},
		'& .MuiImageMarked-root': {
			opacity: 0
		},
		'& .MuiTypography-root': {
			border: '4px solid currentColor'
		}
	}
}));

const ImageSrc = styled('span')({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundPosition: 'center 40%'
});

const Image = styled('span')(({ theme }) => ({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: theme.palette.common.white
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundColor: theme.palette.common.black,
	opacity: 0.4,
	transition: theme.transitions.create('opacity')
}));

const ImageMarked = styled('span')(({ theme }) => ({
	height: 3,
	width: 18,
	backgroundColor: theme.palette.common.white,
	position: 'absolute',
	bottom: -2,
	left: 'calc(50% - 9px)',
	transition: theme.transitions.create('opacity')
}));

export function CreateUser() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const steps = ['Select account type', 'Create account', 'Done'];
	const country = ['US', 'Owner'];
	const role = ['Viewer', 'Observer', 'Supervisor', 'Editor', 'Manager'];
	const cur = ['TWD', 'USD'];
	const [activeStep, setActiveStep] = useState(0);
	const [accType, setAccType] = useState();
	const [areaList, setAreaList] = useState([]);
	const [userVal, setUserVal] = useState({ Name: '', Email: '', ViewArea: [], Type: '', Role: '', TWStaff: false });
	const [storeVal, setStoreVal] = useState({
		Email: '',
		Company: '',
		ContractNo: '',
		CountryCode: '',
		Address: '',
		City: '',
		Currency: '',
		Phone: '',
		Store: ['']
	});

	async function secondStep(e) {
		setActiveStep((prev) => prev + 1);
		setAccType(e.target.innerText);
		if (e.target.innerText === 'New User') {
			const area = await listArea();
			setAreaList(area);
		}
	}

	function resetState() {
		setStoreVal({
			Email: '',
			Company: '',
			ContractNo: '',
			CountryCode: '',
			City: '',
			Address: '',
			Currency: '',
			Phone: '',
			Store: ['']
		});
		setUserVal({ Name: '', Email: '', ViewArea: [], Type: '', Role: '' });
		setAccType();
	}

	function goBack() {
		setActiveStep((prev) => prev - 1);
		resetState();
	}

	const validateEmail = (email) => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return re.test(String(email).toLowerCase());
	};

	function getInput(key, e, v) {
		let val;
		let prev = { ...userVal };
		if (accType === 'New User') {
			val = e.target.value;
			switch (key) {
				case 'ViewArea':
					prev[key] = v;
					break;
				case 'Type':
				case 'Role':
					val = e.target.innerText;
					prev[key] = val || '';
					break;
				case 'TWStaff':
					prev[key] = !prev[key];
					break;
				default:
					prev[key] = val;
					break;
			}
			setUserVal(prev);
		} else {
			prev = { ...storeVal };
			if (key === 'Currency') {
				val = e.target.innerText;
				prev[key] = val || '';
			} else if (key === 'Store') {
				if (e === 'remove') {
					prev[key].splice(v, 1);
				} else {
					val = e.target.value;
					prev[key][v] = val;
				}
			} else if (key === 'CountryCode') {
				val = e.target.value.toUpperCase();
				prev[key] = val;
			} else {
				val = e.target.value;
				prev[key] = val;
			}
			setStoreVal(prev);
		}
	}

	function addStore() {
		let val = { ...storeVal };
		if (!val.Store.includes('')) {
			val.Store.push('');
			setStoreVal(val);
		}
	}

	async function handleSubmit(event) {
		event.preventDefault();
		const data = [];
		if (accType === 'New User') {
			for (const [key, value] of Object.entries(userVal)) {
				if (key === 'Name' || key === 'Email') {
					data.push({ Name: key.toLowerCase(), Value: value });
				}
				if (key === 'ViewArea') {
					data.push({ Name: 'address', Value: value.toString() });
				}
				if (key === 'Type') {
					let zoneinfo = (value === 'US' ? 'Z' : 'Owner') + '_' + userVal.Role;
					if (userVal.TWStaff) {
						zoneinfo = zoneinfo.replace(/^/, 'Y_TW,');
					}
					data.push({ Name: 'zoneinfo', Value: zoneinfo });
				}
			}
			createUser(data);
		} else {
			await API.graphql({
				query: createOverseasStore,
				variables: {
					input: {
						Company: storeVal.Company,
						ContractNo: storeVal.ContractNo,
						Country: storeVal.CountryCode,
						Name: storeVal.City,
						Store: storeVal.Store.map((s) => JSON.stringify(s)),
						Email: storeVal.Email,
						Currency: storeVal.Currency,
						Phone: storeVal.Phone,
						Address: storeVal.Address
					}
				}
			});
		}
		setActiveStep((prev) => prev + 1);
		resetState();
		setTimeout(() => {
			navigate('/dashboard');
		}, 1000);
	}

	return (
		<>
			<Box textAlign={'center'} pt={2} fontWeight={'bold'}>
				{t('account.Create New Account')}
			</Box>
			<Stepper activeStep={activeStep} sx={{ margin: 1 }}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{t('account.' + label)}</StepLabel>
					</Step>
				))}
			</Stepper>
			{activeStep === 0 && (
				<>
					<Typography textAlign={'center'} fontSize={18} pt={2}>
						{t('account.Please select type of the account')}
					</Typography>
					<Box sx={{ minWidth: 300, pt: 2 }} textAlign={'center'}>
						{images.map((image) => (
							<ImageButton
								focusRipple
								key={image.title}
								onClick={secondStep}
								style={{
									width: image.width,
									height: 220,
									border: '1px solid #eaeaea'
								}}>
								<ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
								<ImageBackdrop className="MuiImageBackdrop-root" />
								<Image>
									<Typography
										component="span"
										variant="subtitle1"
										color="inherit"
										sx={{
											position: 'relative',
											p: 4,
											pt: 2,
											pb: (theme) => `calc(${theme.spacing(1)} + 6px)`
										}}>
										{t('account.' + image.title)}
										<ImageMarked className="MuiImageMarked-root" />
									</Typography>
								</Image>
							</ImageButton>
						))}
					</Box>
				</>
			)}
			{activeStep === 1 &&
				(accType === t('account.New Store') ? (
					<Box component={'form'} onSubmit={handleSubmit}>
						<Box justifyContent={'space-between'} display={'flex'}>
							<SoftButton onClick={() => goBack()} variant="contained">
								{t('area.Back')}
							</SoftButton>
							<SoftButton
								type="submit"
								variant="contained"
								color="success"
								disabled={Object.values(storeVal).includes('') || storeVal.Store.map((s) => s).includes('')}>
								{t('area.Save')}
							</SoftButton>
						</Box>
						<Box textAlign={'-webkit-center'} pt={1}>
							<Card sx={{ width: { md: 700, xs: 360 } }}>
								<CardContent sx={{ m: 1 }}>
									<Typography fontWeight={'bold'}>{t('account.Create new store')}</Typography>
									<Grid container p={2}>
										{Object.keys(storeVal).map((v, index) => {
											switch (v) {
												case 'Currency':
													return (
														<Grid item md={6} xs={12} pt={2} pl={2} key={index}>
															<Typography pr={1} fontSize={15} align="left">
																{t('account.' + v)}
															</Typography>
															<Autocomplete
																size="small"
																id="combo-box-demo"
																options={cur}
																renderInput={(params) => <TextField {...params} />}
																onChange={(e, val) => getInput(v, e, val)}
																defaultValue={storeVal[v]}
															/>
														</Grid>
													);
												case 'Store':
													return storeVal.Store.map((s, index) => (
														<Grid item md={6} xs={12} pt={2} pl={2} key={index}>
															<Typography pr={1} fontSize={15} align="left">
																{t('account.' + v)}
															</Typography>
															<Stack direction={'row'}>
																<SoftInput
																	size="small"
																	name={v}
																	onChange={(e) => getInput(v, e, index)}
																	sx={{ width: 350 }}
																	value={s}></SoftInput>
																<Tooltip title="please make sure to fill in" placement="right">
																	<IconButton aria-label="add" size="small" onClick={() => addStore()}>
																		<AddCircleIcon fontSize="small" />
																	</IconButton>
																</Tooltip>
																<IconButton
																	aria-label="add"
																	size="small"
																	onClick={() => getInput(v, 'remove', index)}
																	sx={{ display: index === 0 ? 'none' : undefined }}>
																	<RemoveCircleIcon fontSize="small" />
																</IconButton>
															</Stack>
														</Grid>
													));
												default:
													return (
														<Grid item md={6} xs={12} pt={2} pl={2} key={index}>
															<Stack direction={'row'}>
																<Typography pr={1} fontSize={15} align="left">
																	{t('account.' + v)}
																</Typography>
																<Tooltip title="ex: US, TW. Click to check" placement="right">
																	<a
																		size="small"
																		target="blank"
																		style={{ display: v === 'CountryCode' ? undefined : 'none', color: 'black' }}
																		href="https://www.iban.com/country-codes">
																		<OpenInNewIcon />
																	</a>
																</Tooltip>
															</Stack>
															<SoftInput
																size="small"
																name={v}
																inputProps={{ maxLength: v === 'CountryCode' ? 2 : null }}
																placeholder={v === 'CountryCode' ? 'limit 2 letter' : null}
																onChange={(e) => getInput(v, e, null)}
																value={storeVal[v]}></SoftInput>
														</Grid>
													);
											}
										})}
									</Grid>
									<Typography
										fontSize={16}
										color={'red'}
										sx={{
											display:
												Object.values(storeVal).includes('') ||
												storeVal.Store.map((s) => s).includes('') ||
												!validateEmail(storeVal.Email)
													? undefined
													: 'none'
										}}>
										{validateEmail(storeVal.Email)
											? t('account.Please fill in all columns')
											: t('account.Email is not valid')}
									</Typography>
								</CardContent>
							</Card>
						</Box>
					</Box>
				) : (
					<Box component={'form'} onSubmit={handleSubmit}>
						<Box justifyContent={'space-between'} display={'flex'}>
							<SoftButton onClick={() => goBack()} variant="contained">
								{t('area.Back')}
							</SoftButton>
							<SoftButton
								type="submit"
								variant="contained"
								color="success"
								disabled={
									Object.values(userVal).includes('') ||
									userVal.ViewArea.map((m) => m).includes('') ||
									userVal.ViewArea.length === 0
								}>
								{t('area.Save')}
							</SoftButton>
						</Box>
						<Box textAlign={'-webkit-center'} pt={1}>
							<Card sx={{ width: { md: 700, xs: 360 } }}>
								<CardContent sx={{ m: 2 }}>
									<Typography fontWeight={'bold'}>{t('account.Create new user')}</Typography>
									<Grid container p={2}>
										<Grid item md={6} xs={12} pl={2}>
											<Typography pr={1} fontSize={15} align="left">
												{t('account.Name')}
											</Typography>
											<SoftInput
												size="small"
												onChange={(e) => getInput('Name', e, null)}
												value={userVal.Name}></SoftInput>
										</Grid>
										<Grid item md={6} xs={12} pl={2}>
											<Typography pr={1} fontSize={15} align="left">
												{t('account.Email')}
											</Typography>
											<SoftInput
												size="small"
												onChange={(e) => getInput('Email', e, null)}
												defaultValue={userVal.Email}></SoftInput>
										</Grid>
										<Grid item md={6} xs={12} pt={3} pl={2}>
											<Typography pr={1} fontSize={15} align="left">
												{t('account.Type')}
											</Typography>
											<Stack direction={'row'}>
												<Autocomplete
													size="small"
													id="combo-box-demo"
													options={country}
													sx={{ width: { md: 180, xs: 120 } }}
													renderInput={(params) => <TextField {...params} />}
													onChange={(e) => getInput('Type', e)}
													value={userVal.Type}
												/>
												<Typography fontSize={12} pl={1} pr={1}>
													{t('account.TW Staff')}
												</Typography>
												<Checkbox checked={userVal.TWStaff} onChange={(e) => getInput('TWStaff', e, null)}></Checkbox>
											</Stack>
										</Grid>
										<Grid item md={6} xs={12} pt={3} pl={2}>
											<Typography pr={1} fontSize={15} align="left">
												{t('account.Role')}
											</Typography>
											<Autocomplete
												size="small"
												id="combo-box-demo"
												options={role}
												renderInput={(params) => <TextField {...params} />}
												onChange={(e) => getInput('Role', e, null)}
												value={userVal.Role}
											/>
										</Grid>
										<Grid item md={12} xs={12} pt={3} pl={2}>
											<Typography pr={1} fontSize={15} align="left">
												{t('account.View Area')}
											</Typography>
											<Stack direction={'row'}>
												<Autocomplete
													multiple
													size="small"
													id="combo-box-demo"
													sx={{ width: { xs: 155, md: 500 } }}
													options={areaList}
													ListboxProps={{ style: { maxHeight: 100, overflow: 'auto' } }}
													renderInput={(params) => <TextField {...params} />}
													onChange={(e, v) => getInput('ViewArea', e, v)}
													value={userVal.ViewArea}
												/>
												<Typography p={1} fontSize={14}>
													{t('account.multiple')}
												</Typography>
											</Stack>
										</Grid>
									</Grid>
									<Typography
										fontSize={16}
										color={'red'}
										sx={{
											display:
												Object.values(userVal).includes('') ||
												userVal.ViewArea.map((m) => m).includes('') ||
												userVal.ViewArea.length === 0 ||
												!validateEmail(userVal.Email)
													? undefined
													: 'none'
										}}>
										{validateEmail(userVal.Email)
											? t('account.Please fill in all columns')
											: t('account.Email is not valid')}
									</Typography>
								</CardContent>
							</Card>
						</Box>
					</Box>
				))}
			{activeStep === 2 && (
				<Box textAlign={'center'}>
					<Typography fontSize={18} py={2}>
						{t('account.New account created')}
					</Typography>
					<img src={userCreated} width={'35%'} />
				</Box>
			)}
		</>
	);
}
