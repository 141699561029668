import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@aws-amplify/ui-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { listOverseasShippings } from '../../graphql/queries';
import { deleteOverseasShipping } from '../../graphql/mutations';
import SoftBox from '../../components/SoftBox';
import SoftButton from '../../components/SoftButton/index';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditIcon from '@mui/icons-material/Edit';

function Inventory() {
	const [item, setItem] = useState([]);
	const [itemValue, setItemValue] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [category, setCategory] = useState([]);
	const [delVal, setDelVal] = useState({});
	const navigate = useNavigate();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('');
	const [searchItem, setsearchItem] = useState('');
	const { userZone } = useSelector((state) => state.user);
	const role = userZone.includes('Y_TW') ? 'TW' : 'US';
	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a[orderBy], b[orderBy])
			: (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
	};

	const descendingComparator = (a, b) => {
		if (b < a) return -1;
		if (b > a) return 1;
		return 0;
	};

	const sortedItem = item.sort(getComparator(order, orderBy));
	const filteredItems = sortedItem.filter((item) => {
		let ignore = new RegExp(searchItem, 'i'); //ignore case-sensitive
		return ignore.test(item.Product) || item.Code.includes(searchItem.toUpperCase()) || item.ZH.includes(searchItem);
	});

	const handleSort = (columnId) => {
		const isAsc = orderBy === columnId && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(columnId);
	};

	const handleSearchChange = (event) => {
		setsearchItem(event.target.value);
	};

	const handleClickOpen = (val) => {
		setDelVal(val);
		setOpen(true);
	};

	const handleClose = () => {
		setDelVal({});
		setOpen(false);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	const columns = [
		{ field: 'Action', headerName: '選項', width: 120 },
		{ field: 'Product', headerName: '商品', width: 160 },
		{ field: 'Category', headerName: '類別', width: 120 },
		{ field: 'Code', headerName: '商品代碼', width: 150 },
		{ field: 'ZH', headerName: '中文', width: 180 },
		{ field: 'Size', headerName: '尺寸', width: 120 },
		{ field: 'Expiry', headerName: '有效期限', width: 120 },
		{ field: 'Unit', headerName: '單位', width: 90 },
		{ field: 'Note', headerName: '註記', width: 150 },
		{ field: 'HSCode', headerName: 'HSCode', width: 120 },
		{ field: 'Brand', headerName: '品牌', width: 120 },
		{ field: 'Material', headerName: '材質', width: 120 },
		{ field: 'ShipFrom', headerName: '出貨地', width: 130 },
		{ field: 'Area', headerName: '地區', width: 90 },
		{ field: 'Price', headerName: '價格', width: 90 },
		{ field: 'Moq', headerName: '數量限制', width: 120 },
		{ field: 'NW', headerName: '淨重', width: 90 },
		{ field: 'GW', headerName: '毛重', width: 90 },
		{ field: 'CartonSize', headerName: '箱子體積', width: 120 }
	];

	useEffect(() => {
		getItem();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role]);

	async function getItem() {
		let items = [];
		let itemValue = [];
		let category = [];
		let uniCategory = [];
		let area = [];
		const variables = {
			limit: 500,
			filter: {
				_deleted: { ne: true }
			}
		};
		try {
			if (role !== 'TW') {
				variables.filter.ShipFrom = { eq: role };
			}
			let hasNextPage = true;
			while (hasNextPage) {
				const { data: res } = await API.graphql({ query: listOverseasShippings, variables });
				const { items: item, nextToken } = res.listOverseasShippings;
				variables.nextToken = nextToken;
				area = area.concat(item);
				hasNextPage = nextToken ? true : false;
			}
			const posts = area;
			for (let i in posts) {
				if (posts[i] !== undefined) {
					items.push(posts[i]);
					category.push(posts[i].Category);
					uniCategory = [...new Set(category)];
				}
			}
			setItem(items);
			setCategory(uniCategory);
			const column = Object.keys(items[0]);
			for (let i in column) {
				itemValue.push({ id: column[i], label: column[i] });
				if (itemValue[0]['label'] === 'id') {
					itemValue[0]['label'] = 'Action';
				}
			}
			setItemValue(itemValue);
		} catch (error) {
			console.log('Error retrieving inv', error);
		}
	}

	async function deleteItem() {
		try {
			const variables = {
				id: delVal.id,
				_version: delVal._version
			};
			await API.graphql({
				query: deleteOverseasShipping,
				variables: { input: variables }
			});
		} catch (error) {
			console.log('error', error);
		}
		setsearchItem('');
		setOpen(false);
		setPage(0);
		getItem();
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={8} pr={3}>
					<TextField
						variant="outlined"
						value={searchItem}
						onChange={handleSearchChange}
						placeholder={t('edit.Search product, code, ZH')}
						// width='80%'
					/>
				</Grid>
				<Grid item xs={12} md={4} pt={2}>
					<SoftButton
						sx={{ marginRight: 2 }}
						color="primary"
						size="small"
						variant="outlined"
						onClick={() => {
							navigate('/inventory/edit', { state: { item: itemValue, category: category } });
						}}>
						{t('edit.Add+')}
					</SoftButton>
					<SoftButton
						color="dark"
						size="small"
						variant="outlined"
						onClick={() => {
							navigate('/inventory/area', { state: { item: item, category: category } });
						}}>
						{t('area.Manage')}
					</SoftButton>
				</Grid>
			</Grid>
			<SoftBox pt={2} pb={6}>
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<TableContainer sx={{ maxHeight: 540 }}>
						<Table
							stickyHeader
							aria-label="sticky table"
							sx={{
								'& .MuiTableHead-root': {
									display: 'table-header-group'
								},
								'& .css-jr1qcz-MuiTableCell-root': {
									backgroundColor: 'lightGrey'
								}
							}}>
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={'tableRow' + column.field}
											align={'center'}
											style={{ minWidth: column.width, fontWeight: 'bold', fontSize: 14, lineHeight: 1.5 }}>
											<TableSortLabel
												active={orderBy === column.field}
												direction={orderBy === column.field ? order : 'asc'}
												onClick={() => {
													if (column.field !== 'Action') {
														handleSort(column.field);
													}
												}}
												sx={{
													'& .css-1oqyonh-MuiSvgIcon-root-MuiTableSortLabel-icon': {
														opacity: '100 !important'
													}
												}}>
												{t('inventory.' + column.field)}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredItems.length > 0 ? (
									filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={'tabIndex' + index + row.code}>
												{columns.map((column) => {
													let value = row[column.field];
													if (value === null || value === 'n/a') {
														value = 'N/A';
													}
													return typeof value === 'object' || column.field === 'Price' ? (
														<TableCell key={'tableCell' + index + column.field} align={'center'}>
															{(() => {
																if (column.field === 'Price' && value !== 'N/A') {
																	return Object.keys(JSON.parse(value))
																		.sort()
																		.map((key, index) => (
																			<div key={'cell' + index}>
																				$
																				{JSON.parse(value)[key].toLocaleString(undefined, { minimumFractionDigits: 2 })}
																			</div>
																		));
																} else {
																	return Object.values(value)
																		.sort()
																		.map((val, index) => <div key={'cells' + index}>{val}</div>);
																}
															})()}
														</TableCell>
													) : (
														<TableCell key={'tableCell2' + column.field} align={'center'}>
															{(() => {
																if (column['field'] === 'Action') {
																	let delVal = filteredItems[filteredItems.indexOf(row)];
																	return (
																		<div>
																			<Tooltip title={t('inventory.Edit')}>
																				<IconButton
																					aria-label="edit"
																					color="default"
																					onClick={() => {
																						navigate('/inventory/edit', {
																							state: {
																								item: itemValue,
																								category: category,
																								val: filteredItems[filteredItems.indexOf(row)]
																							}
																						});
																					}}>
																					<EditIcon />
																				</IconButton>
																			</Tooltip>
																			<Tooltip title={t('inventory.Delete')}>
																				<IconButton
																					aria-label="delete"
																					color="error"
																					onClick={() => handleClickOpen(delVal)}>
																					<DeleteForeverRoundedIcon />
																				</IconButton>
																			</Tooltip>
																		</div>
																	);
																} else {
																	return <div>{value}</div>;
																}
															})()}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})
								) : (
									<>
										<TableRow>
											<TableCell colSpan={10}>
												<Skeleton animation="wave" />
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={10}>
												<Skeleton animation="wave" />
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell colSpan={10}>
												<Skeleton animation="wave" />
											</TableCell>
										</TableRow>
									</>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[25, 50, 75]}
						component="div"
						count={filteredItems.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						sx={{
							'& .css-1ui3wbn-MuiInputBase-root-MuiTablePagination-select': {
								width: 'fit-content !important'
							}
						}}
					/>
				</Paper>
			</SoftBox>
			<Dialog
				sx={{
					'& .css-1l52pf8-MuiButtonBase-root-MuiButton-root:hover': {
						bgcolor: '#adb2b3',
						color: '#0b0b0b'
					},
					'& .css-1l52pf8-MuiButtonBase-root-MuiButton-root': { color: '#0b0b0b' }
				}}
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title" fontWeight="bold">
					{t('inventory.This is going to delete the item.')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" fontSize="16px" align="center">
						<Typography fontSize={16}>{t('Are you sure to delete')}</Typography>
						{delVal.id ? <Typography fontWeight={'bold'} fontSize={16}>{`${delVal.Product}?`}</Typography> : null}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="outlined" style={{ color: '#e6280f', borderColor: '#e6280f' }}>
						{t('Discard')}
					</Button>
					<Button onClick={deleteItem} variant="outlined" style={{ color: '#019923de', borderColor: '#019923de' }}>
						{t('Yes')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Inventory;
